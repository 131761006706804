import { RequestUserSearchParameters } from "../../models/common/request-parameters.model";
import { UserDeactivationModel } from "../../models/user/user-deactivation.model";
import { UserListModel } from "../../models/user/user-list.model";

export class SelectUserDeactivationAction {
    static readonly type = '[User Deactivations] Select';
    constructor(public data : UserDeactivationModel) {}
 }
  
 export class GetUserDeactivationAction {
    static readonly type = '[User Deactivations] Get User Deactivation List';
    constructor() {}
 }

 export class SelectUsersAction {
   static readonly type = '[Users] Select';
   constructor(public data : UserListModel) {}
}
 
export class GetUsersAction {
   static readonly type = '[Users] Get User List';
   constructor(public requestUserSearchParameters: RequestUserSearchParameters) {}
}
