<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>User Deactivations</h5>
        </div>
        <div class="card-body">
            <div class="btn-popup pull-right">
                <a class="btn btn-primary">Deactivate User/s</a>
            </div>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" sortable="fullname" (sort)="onSort($event)">Full Name</th>
                                <th scope="col" sortable="contNum1" (sort)="onSort($event)">Contact Number 1</th>
                                <th scope="col" sortable="contNum2" (sort)="onSort($event)">Contact Number 2</th>
                                <th scope="col" sortable="email" (sort)="onSort($event)">Email</th>
                                <th scope="col" sortable="accountLocked" (sort)="onSort($event)">Account Locked?</th>
                                <th scope="col" sortable="active" (sort)="onSort($event)">Activate User?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of users$ | async ">
                                <td>{{item.firstName}} {{item.lastName}}</td>
                                <td>{{item.contNum1}}</td>
                                <td>{{item.contNum2}}</td>
                                <td>{{item.email}}</td>
                                <td>{{item.accountLocked}}</td>
                                <td>
                                    <input type="checkbox" name="active" onclick="onSelect(item.id)">
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <div class="d-flex justify-content-center p-2">
                        <ngb-pagination
                          [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->