import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Router } from '@angular/router';
import { BillingsubscriptionService, MerchantService } from '../../services';
import { GetBillingSubscriptionAction, SelectBillingSubscriptionAction } from '../actions/billing-subscription.action';
import { BillingSubscriptionModel } from '../../models/billing-subscription/billing-subscription.model';

export class BillingSubscriptionStateModel {
    public previousBillingSubscription: BillingSubscriptionModel;
}

@State<BillingSubscriptionStateModel>({
    name: 'billingSubscriptions',
    defaults: {
        previousBillingSubscription: null
    }
})

@Injectable()
export class BillingSubscriptionState {

    constructor(private _billingSubscriptionService: BillingsubscriptionService,
        private _router: Router) {
    }

    @Selector()
    public static selectedBillingSubscription(state: BillingSubscriptionStateModel) {
        return state.previousBillingSubscription;
    }

    @Action(SelectBillingSubscriptionAction)
    public selectBillingScriptionAction(ctx: StateContext<BillingSubscriptionStateModel>, action: SelectBillingSubscriptionAction) {
        ctx.patchState({ previousBillingSubscription: action.data });
    }

    @Action(GetBillingSubscriptionAction)
    public getBillingScriptionAction(ctx: StateContext<BillingSubscriptionStateModel>, action: GetBillingSubscriptionAction) {
        return this._billingSubscriptionService.getBillingSubscriptions(action.requestLimitParameters).subscribe(data => {
            ctx.patchState({ previousBillingSubscription: data });
        })
    }

}
