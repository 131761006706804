import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-aps-modal',
  templateUrl: './aps-modal.component.html',
  styleUrls: ['./aps-modal.component.scss']
})
export class APSModalComponent implements OnInit, OnDestroy {

  @ViewChild("apsModal", { static: false }) APSModal: TemplateRef<any>;

  @Output() apsSuccess: EventEmitter<any> = new EventEmitter();
  @Output() apsFail: EventEmitter<any> = new EventEmitter();

  public closeResult: string;
  public modalOpen: boolean = false;
  modal: NgbModalRef;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  postToAPS(apsApiUrl: string, creqValue: string) {
    this.post(apsApiUrl,
      {
        creq: creqValue
      });
  }

  post(path: any, parameters: any) {
    var form = $('<form id="threedsChallengeRedirectForm" target="challengeFrame" action="url-to-server-Page"></form>');
    form.attr("method", "post");
    form.attr("action", path);
    $.each(parameters, function (key, value) {
      var field = $('<input></input>');
      field.attr("type", "hidden");
      field.attr("name", key as any);
      field.attr("value", value);
      form.append(field);
    });
    try {
      $("#challengeFrame").remove('#threedsChallengeRedirectForm');
    } catch (e) {
    }
    try {
      var height = $(window).height() ?? 0;
      $("#challengeFrame").css('height', height * 0.9 | 0);
      $("#challengeFrame").contents().scrollTop(999999);
    }
    catch (e) {
      alert(e);
    }
    // Append the form to the challengeFrame
    $("#challengeFrame").append(form);

    // Submit the form
    form.submit();

    // Additional script logic
    var e = document.getElementById("threedsChallengeRedirectForm") as HTMLFormElement;
    if (e) {
      e.submit();
      if (e.parentNode !== null) {
        e.parentNode.removeChild(e);
      }
    }
  }

  async openModal(apsApiUrl: string, creqValue: string) {
    this.modalOpen = true;
    this.modal = this.modalService.open(this.APSModal, {
      ariaLabelledBy: 'APS-Modal',
      centered: true,
      size: 'apssize',
      windowClass: 'aps-modal'
    });

    this.modal.result.then((result) => {
      `Result ${result}`
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.postToAPS(apsApiUrl, creqValue);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  onComplete(result: any) {
    this.apsSuccess.emit(result);
    this.modal.dismiss('close');
  }

  onFail(result: any) {
    this.apsFail.emit(result);
    this.modal.dismiss('close');
  }

}
