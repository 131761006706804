import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Router } from '@angular/router';
import { MerchantService, SettlementService } from '../../services';
import { PreviousSettlementModel } from '../../models/settlement/previous-settlement.model';
import { GetPreviousSettlementAction, SelectPreviousSettlementAction } from '../actions/settlement.action';

export class PreviousSettlementStateModel {
  public previousSettlements: PreviousSettlementModel;
}

@State<PreviousSettlementStateModel>({
  name: 'previousSettlement',
  defaults: {
    previousSettlements: null
  }
})

@Injectable()
export class SettlementState {

    constructor(private _settlementService: SettlementService,
    private _router: Router){
  }

  @Selector()
  public static selectedPreviousSettlements(state: PreviousSettlementStateModel) {
    return state.previousSettlements;
  }

  @Action(SelectPreviousSettlementAction)
  public selectPreviousSetttlementAction(ctx: StateContext<PreviousSettlementStateModel>, action: SelectPreviousSettlementAction) {
    ctx.patchState({ previousSettlements: action.data});
  }

  @Action(GetPreviousSettlementAction)
  public getPreviousSetttlementAction(ctx: StateContext<PreviousSettlementStateModel>, action: GetPreviousSettlementAction) {
    return this._settlementService.getPreviousSettlement(action.requestParameters).subscribe(data => {
      ctx.patchState({ previousSettlements: data});
    })  
  }

}
