import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { UserDeactivationModel } from "../../models/user/user-deactivation.model";
import { UserService } from "../../services";
import { GetUserDeactivationAction, SelectUserDeactivationAction } from "../actions/user.action";

export class UserDeactivationStateModel {
    public userDeactivations: UserDeactivationModel;
  }
  
  @State<UserDeactivationStateModel>({
    name: 'userDeactivations',
    defaults: {
      userDeactivations: null
    }
  })
  
  @Injectable()
  export class UserDeactivationState {
  
      constructor(private _userService: UserService,
      private _router: Router){
    }
  
    @Selector()
    public static selectedUserDeactivations(state: UserDeactivationStateModel) {
      return state.userDeactivations;
    }
  
    @Action(SelectUserDeactivationAction)
    public selectUserDeactivationsAction(ctx: StateContext<UserDeactivationStateModel>, action: SelectUserDeactivationAction) {
      ctx.patchState({ userDeactivations: action.data});
    }
  
    @Action(GetUserDeactivationAction)
    public getUserDeactivationsAction(ctx: StateContext<UserDeactivationStateModel>, action: GetUserDeactivationAction) {
      return this._userService.getUserDeactivations().subscribe(data => {
        ctx.patchState({ userDeactivations: data});
      })  
    }
  
  }