import { RequestParameters } from "../../models/common/request-parameters.model";
import { PreviousSettlementModel } from "../../models/settlement/previous-settlement.model";

export class SelectPreviousSettlementAction {
    static readonly type = '[Previous Settlement] Select';
    constructor(public data : PreviousSettlementModel) {}
 }
  
 export class GetPreviousSettlementAction {
    static readonly type = '[Previous Settlement] Get by Request Parameters';
    constructor(public requestParameters: RequestParameters) {}
 }