import { DebtorSummaryTransactionParameters } from "../../models/common/request-parameters.model";
import { DebtorSummaryItemModel } from "../../models/recurring/debtor-summary-item.model";
import { DebtorSummaryTransactionsModel } from "../../models/recurring/debtor-summary-transactions.model";
import { RecurringPaymentTransactionReportModel } from "../../models/recurring/recurring-payment-transaction-report.model";

export class SelectDebtorRecurringSummaryAction {
    static readonly type = '[Debtor Recurring Summary] Select';
    constructor(public data : DebtorSummaryTransactionsModel) {}
 }
  
 export class GetDebtorRecurringSummaryAction {
    static readonly type = '[Debtor Recurring Summary] Get by Debtor Summary Transaction Parameters';
    constructor(public debtorSummaryTransactionParameters: DebtorSummaryTransactionParameters) {}
 }

 export class SelectDebtorAdministrationSummaryAction {
   static readonly type = '[Debtor Administration Summary] Select';
   constructor(public data : DebtorSummaryItemModel[]) {}
}
 
export class GetDebtorAdministrationSummaryAction {
   static readonly type = '[Debtor Administration Summary] Get by Debtor Administration Parameters';
   constructor(public debtorSummaryTransactionParameters: DebtorSummaryTransactionParameters) {}
}

export class SelectRecurringPaymentTransactionReportAction {
   static readonly type = '[Recurring Payment Transaction Report] Select';
   constructor(public data : RecurringPaymentTransactionReportModel[]) {}
}
 
export class GetRecurringPaymentTransactionReportAction {
   static readonly type = '[Recurring Payment Transaction Report] Get by Debtor Administration Parameters';
   constructor(public debtorSummaryTransactionParameters: DebtorSummaryTransactionParameters) {}
}