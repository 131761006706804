import { Component } from '@angular/core';
import { vendorsDB } from 'src/app/shared/tables/vendor-list';

@Component({
  selector: 'app-merchant-list',
  templateUrl: './merchant-list.component.html',
  styleUrls: ['./merchant-list.component.scss']
})
export class MerchantListComponent {

  public vendors = [];

  constructor() {
    this.vendors = vendorsDB.data;
  }

  public settings = {
    actions: {
      position: 'right'
    },
    columns: {
      vendor: {
        title: 'Vendor',
        type: 'html',
      },
      products: {
        title: 'Products'
      },
      store_name: {
        title: 'Store Name'
      },
      date: {
        title: 'Date'
      },
      balance: {
        title: 'Wallet Balance',
      },
      revenue: {
        title: 'Revenue',
      }
    },
  };

  ngOnInit() {
  }

}
