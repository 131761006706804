export class productDB {
    static product = [
        {
            img: "assets/images/electronics/product/1.jpg",
            product_title: "Main Tuck Shop",
            discount: "",
            price: "",
            sale: "",
            tag: ""
        },
        {
            img: "assets/images/electronics/product/2.jpg",
            product_title: "Unifrom Shop",
            discount: "",
            price: "",
            sale: "",
            tag: ""
        },
        {
            img: "assets/images/electronics/product/3.jpg",
            product_title: "Book Shop",
            discount: "",
            price: "",
            sale: "",
            tag: ""
        },
        {
            img: "assets/images/electronics/product/4.jpg",
            product_title: "Finance Office",
            discount: "",
            price: "",
            sale: "",
            tag: ""
        },
        {
            img: "assets/images/furniture/product/4.jpg",
            product_title: "Slim Fit Cotton Shirt",
            discount: "$500.00",
            price: "$600.00",
            sale: "not on sale",
            tag: "old"
        },
        {
            img: "assets/images/furniture/product/4.jpg",
            product_title: "Slim Fit Cotton Shirt",
            discount: "$500.00",
            price: "$600.00",
            sale: "not on sale",
            tag: "old"
        },
        {
            img: "assets/images/furniture/product/4.jpg",
            product_title: "Slim Fit Cotton Shirt",
            discount: "$500.00",
            price: "$600.00",
            sale: "not on sale",
            tag: "old"
        },
        {
            img: "assets/images/furniture/product/4.jpg",
            product_title: "Slim Fit Cotton Shirt",
            discount: "$500.00",
            price: "$600.00",
            sale: "not on sale",
            tag: "old"
        }
    ]
}
