<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <div class="logo-wrapper"><img class="blur-up lazyloaded" src="assets/images/logos/c2p_logo.png" alt="">
            </div>
        </div>
        <div class="card-body tab2-card">
            <div *ngIf="isLoading">
                <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
                    [fullScreen]="false">
                    <br />
                    <br />
                    <br />
                    <p style="color: white"> Loading ... </p>
                </ngx-spinner>
            </div>
            <form [formGroup]="cardTransactionForm" class="needs-validation" novalidate="">
                <h4>Card Transaction</h4>
                <div class="row">
                    <div class="col-sm-10">
                        <div class="form-group row">
                            <div class="col-md-2">
                                <label for="validationCustom0">
                                    Transaction Reference:</label>
                            </div>
                            <div class="col-md-3">
                                <h5>{{ cardTransactionDetail?.transactionReference }}</h5>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2">
                                <label for="validationCustom1">
                                    Transaction Amount:</label>
                            </div>
                            <div class="col-md-3">
                                <h5>R{{ cardTransactionDetail?.transactionAmount | number:'1.2-2' }}</h5>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2">
                                <label for="validationCustom1">
                                    Card Holder Name:</label>
                            </div>
                            <div class="col-md-3">
                                <h5>{{ cardTransactionDetail?.cardHolderName }}</h5>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2">
                                <label for="validationCustom1">
                                    Card Number</label>
                            </div>
                            <div class="col-md-4">
                                <input class="form-control" formControlName="cardNumber" id="cardNumber" type="number"
                                    min="1" required="">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2">
                                <label for="validationCustom1">
                                    Card Expiry:</label>
                            </div>
                            <div class="col-sm-1">
                                <input class="form-control" formControlName="expiryMonth" id="expiryMonth" type="number"
                                    min="1" max="12" placeholder="MM" required>
                            </div>
                            <div class="col-sm-1">
                                <input class="form-control" formControlName="expiryYear" id="expiryYear" type="number"
                                    min="24" placeholder="YY" required>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2">
                                <label for="validationCustom1">
                                    CVV:</label>
                            </div>
                            <div class="col-sm-1">
                                <input class="form-control" formControlName="cvv" id="cvv" type="number" min="0"
                                    required="">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2">
                                <label for="validationCustom1">
                                    Mobile:</label>
                            </div>
                            <div class="col-md-3">
                                <h5>{{ cardTransactionDetail?.mobile }}</h5>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2">
                                <label for="validationCustom1">
                                    Email:</label>
                            </div>
                            <div class="col-md-3">
                                <h5>{{ cardTransactionDetail?.email }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="pull-right">
                <button type="button" class="btn btn-primary" (click)="processCardTransaction()">Process</button>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<app-aps-modal #apsModal></app-aps-modal>