<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Users</h5>
        </div>
        <div class="card-body">
            <div class="btn-popup pull-right">
                <a class="btn btn-primary" [routerLink]="'/users/create-user'">Create User</a>
            </div>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" >Edit</th>
                                <th scope="col" sortable="firstName" (sort)="onSort($event)">First Name</th>
                                <th scope="col" sortable="lastName" (sort)="onSort($event)">Last Name</th>
                                <th scope="col" sortable="merchantName" (sort)="onSort($event)">Merchant Name</th>
                                <th scope="col" sortable="mobile" (sort)="onSort($event)">Mobile</th>
                                <th scope="col" sortable="email" (sort)="onSort($event)">Email</th>
                                <th scope="col" sortable="authSettle" (sort)="onSort($event)">Auth & Settle</th>
                                <th scope="col" sortable="typeDescription" (sort)="onSort($event)">Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of users$ | async ">
                                <td>
                                    <a href="javascript:void(0)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                </td>
                                <td>
                                    {{item.firstName}}
                                </td>
                                <td>{{item.lastName}}</td>
                                <td>{{item.merchantName}}</td>
                                <td>{{item.mobile}}</td>
                                <td>{{item.email}}</td>
                                <td>{{item.authSettle}}</td>
                                <td>{{item.typeDescription}}</td>
                            </tr>
                        </tbody>
                    </table> 
                    <div class="d-flex justify-content-center p-2">
                        <ngb-pagination
                          [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->