export class ProcessCardTransactionRequestModel {
    public transactionReference: string;
    public transactionAmount: number;
    public currency: string;
    public cardHolderName: string;
    public cardNumber: string;
    public cardExpiry: string;
    public cardCVV: string;
    public tokenise: boolean;
    public redirectUrl: string;
    public transactionRequestIdentifier: string;
  }
  