import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { PaymentStatusResponseModel } from 'src/app/core/models/recurring/payment-status-response.model';
import { RecurringService } from 'src/app/core/services';

@Component({
  selector: 'app-customer-payment-status',
  templateUrl: './customer-payment-status.component.html',
  styleUrls: ['./customer-payment-status.component.scss']
})
export class CustomerPaymentStatusComponent implements OnInit, OnDestroy {

  public paymentStatusModel: PaymentStatusResponseModel;

  public isLoading: boolean = false;
  public isSuccess: boolean = false;
  public isWarning: boolean = false;
  public hasResult: boolean = false;
  public subscription: Subscription;

  public IdParam: string;

  constructor(private route: ActivatedRoute, private spinner: NgxSpinnerService,
    private recurringService: RecurringService) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.IdParam = params.get('id');
      if (this.IdParam != null) {
        this.loadPaymentStatus(this.IdParam);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadPaymentStatus(paymentFormRequestIdentifier: string) {
    this.isLoading = true;

    this.spinner.show();

    this.recurringService.getPaymentStatus(paymentFormRequestIdentifier).subscribe({
      next: (data: PaymentStatusResponseModel) => {
        this.hasResult = true;

        this.paymentStatusModel = {
          transactionStatus: data.transactionStatus,
          transactionResult: data.transactionResult
        };

        if (this.paymentStatusModel.transactionStatus == 'Success') {
          this.isSuccess = true;
        }

        if (this.paymentStatusModel.transactionStatus == "Warning") {
          this.isWarning = true;
        }

        this.isLoading = false;
        this.spinner.hide();

      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.spinner.hide();
      }
    })
  }

}
