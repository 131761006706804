<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <!-- <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-warning card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'users'" class="font-warning"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Tracey Domalik</span>
                            <h3 class="mb-0">R <span class="counter">250.00</span><small></small></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden  widget-cards">
                <div class="bg-light-color card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'users'" class="font-light-color"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Trinity Domalik</span>
                            <h4 class="mb-0">R <span class="counter">150.00</span><small> </small></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-light-color card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'users'" class="font-light-color"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Sebastian Domalik</span>
                            <h4 class="mb-0">R <span class="counter">50.00</span><small> </small></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-light-color card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'users'" class="font-light-color"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Raphael Domalik</span>
                            <h4 class="mb-0">R <span class="counter">200.00</span><small> </small></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Collections</h5>
                </div>
                <div class="card-body">
                    <div class="market-chart">
                        <x-chartist class="board-chart ct-golden-section" [configuration]="chart3">
                        </x-chartist>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 xl-100">
            <div class="card report-employee">
                <div class="card-header">
                    <h2>75%</h2>
                    <h6 class="mb-0">Card Collection Ratio</h6>
                </div>
                <div class="card-body p-0 o-hidden">
                    <div class="ct-4 flot-chart-container report-full">
                            <x-chartist [configuration]="chart5"></x-chartist>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-xl-3 col-md-6 xl-50">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h5>Outstandng Payments</h5>
                    <div class="row">
                        <div class="col-6">
                            
                        </div>
                        <div class="col-6">
                            <div class="">
                                <h3> <span><i class=""></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-start">
                        <div class="media-body">
                            <span></span>
                            <h5 class="mb-0"></h5>
                            <p> <span><i class=""></i></span></p>
                            <h5 class="f-w-600"></h5>
                            <p></p>
                        </div>
                        <div class="bg-primary b-r-8">
                            <div class="small-box">
                                <i data-feather="credit-card"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h5>Early Window Scheduling</h5>
                    <div class="row">
                        <div class="col-6">
                           
                        </div>
                        <div class="col-6">
                            <div class="value-graph">
                                <h3> <span><i class=""></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-start">
                        <div class="media-body">
                            <span></span>
                            <h2 class="mb-0"></h2>
                            <p> <span><i class="fa fa-angle-up"></i></span></p>
                            <h5 class="f-w-600"></h5>
                            <p></p>
                        </div>
                        <div class="bg-secondary b-r-8">
                            <div class="small-box">
                                <i data-feather="credit-card"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h5>Transaction Submissions</h5>
                    <div class="row">
                        <div class="col-6">
                           
                        </div>
                        <div class="col-6">
                            <div class="value-graph">
                                <h3> <span><i class=""></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-start">
                        <div class="media-body">
                            <span></span>
                            <h2 class="mb-0"></h2>
                            <p> <span><i class=""></i></span></p>
                            <h5 class="f-w-600"></h5>
                            <p></p>
                        </div>
                        <div class="bg-warning b-r-8">
                            <div class="small-box">
                                <i data-feather="shopping-cart"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h5>Collection Summary</h5>
                    <div class="row">
                        <div class="col-6">
                          
                        </div>
                        <div class="col-6">
                            <div class="">
                                <h3> <span><i class=""></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-start">
                        <div class="media-body">
                            <span></span>
                            <h2 class="mb-0"></h2>
                            <p> <span><i class="fa fa-angle-up"></i></span></p>
                            <h5 class="f-w-600"></h5>
                            <p></p>
                        </div>
                        <div class="bg-danger b-r-8">
                            <div class="small-box">
                                <i data-feather="calendar"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Transactions</h5>
                </div>
                
            </div>
        </div> -->
        <!-- <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>My Tickets</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive products-table">
                        <table class="table table-bordernone mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Details</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Madonna</td>
                                    <td class="digits">1</td>
                                    <td class="font-primary">Pending</td>
                                    <td class="digits">R6523</td>
                                </tr>
                                <tr>
                                    <td>Woodstock</td>
                                    <td class="digits">5</td>
                                    <td class="font-secondary">Confirmed</td>
                                    <td class="digits">R6523</td>
                                </tr>
                                <tr>
                                    <td>Blink 183</td>
                                    <td class="digits">10</td>
                                    <td class="font-secondary">Confirmed</td>
                                    <td class="digits">R523</td>
                                </tr>
                                <tr>
                                    <td>Boys College Social</td>
                                    <td class="digits">9</td>
                                    <td class="font-primary">Confirmed</td>
                                    <td class="digits">R6523</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Tranfer Funds</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive products-table">
                        <table class="table table-bordernone mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Who would you like to transfer funds to?</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/user2.jpg" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>Trinity Domalik <span class="text-muted digits"></span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-primary" role="progressbar"
                                                    style="width: 30%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">Account: 586957</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/user1.jpg" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>Sebastian Domalik <span class="text-muted digits"></span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-secondary" role="progressbar"
                                                    style="width: 70%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">Account: 569874</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/man.png" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>Raphael Domalik<span class="text-muted digits"></span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-primary" role="progressbar"
                                                    style="width: 60%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">Account: 574589</td>
                                </tr>
                                <tr>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Monthly Summary</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph overflow-hidden">
                                <h6>Collections By Frequency</h6>
                                <div class="chart-block chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="pieData" [explodeSlices]="false"
                                        [labels]="doughnutChartShowLabels" [arcWidth]=0.50 [doughnut]="true"
                                        [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="order-graph-bottom">
                                    <div class="media">
                                        <div class="order-color-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Daily <span class="pull-right">R1573.00</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-secondary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Default <span class="pull-right">R34773.00</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-danger"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Monthly<span class="pull-right">R46838.00</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-warning"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Weekly<span class="pull-right">R12562.00</span></h6>
                                        </div>
                                    </div>
                                    <!-- <div class="media">
                                        <div class="order-color-success"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Tuck shop <span class="pull-right">R647</span></h6>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space overflow-hidden">
                                <h6>Recurring Card Collections By Status</h6>
                                <div class="peity-chart-dashboard text-center chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="doughnutData"
                                        [explodeSlices]="false" [labels]="doughnutChartShowLabels" [arcWidth]=0.99
                                        [doughnut]="true" [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="order-graph-bottom sales-location">
                                    <div class="media">
                                        <div class="order-shape-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Failures<span class="pull-right">15%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-secondary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Successes <span class="pull-right">75%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-danger"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">AutoRuns<span class="pull-right">34%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-warning"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Dynamic Payday<span class="pull-right">90%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-success"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Early December Collection <span class="pull-right">25%</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 xl-100">
                            <div class="order-graph xl-space">
                                <h6>Recurring Collections</h6>
                                <div class="ct-4 flot-chart-container">
                                     <div class="card-body">
                    <div class="market-chart">
                        <x-chartist class="board-chart ct-golden-section" [configuration]="chart3">
                        </x-chartist>
                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Container-fluid Ends-->