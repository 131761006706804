import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { CardTransactionResultModel } from "../../models/transaction/card-transaction-result.model";
import { TransactionService } from "../../services";
import { SelectCardTransactionResultAction, GetCardTransactionResultAction } from "../actions/transaction.action";

export class CardTransactionResultStateModel {
    public selectedCardTransactionResult: CardTransactionResultModel;
  }
  
  @State<CardTransactionResultStateModel>({
    name: 'cardTransactionResult',
    defaults: {
        selectedCardTransactionResult: null
    }
  })
  
  @Injectable()
  export class CardTransactionResultState {
  
      constructor(private _transactionService: TransactionService,
      private _router: Router){
    }
  
    @Selector()
    public static selectedCardTransactionResult(state: CardTransactionResultStateModel) {
      return state.selectedCardTransactionResult;
    }
  
    @Action(SelectCardTransactionResultAction)
    public selectCardTransactionResultAction(ctx: StateContext<CardTransactionResultStateModel>, action: SelectCardTransactionResultAction) {
      ctx.patchState({ selectedCardTransactionResult: action.data});
    }
  
    @Action(GetCardTransactionResultAction)
    public getCardTransactionResultAction(ctx: StateContext<CardTransactionResultStateModel>, action: GetCardTransactionResultAction) {
      return this._transactionService.getCardTransactionResult(action.bankReference).subscribe(data => {
        ctx.patchState({ selectedCardTransactionResult: data});
      })  
    }
  
  }
  