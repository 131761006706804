import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomerPaymentFormComponent } from "./customer-payment-form/customer-payment-form.component";
import { CustomerPeachPaymentFormComponent } from "./customer-peach-payment-form/customer-peach-payment-form.component";

const routes: Routes = [
    {
        path: 'card-payment',
        component: CustomerPaymentFormComponent,
    },
    {
        path: 'card-process',
        component: CustomerPeachPaymentFormComponent,
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CustomerRoutingModule { }
