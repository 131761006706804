import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { config } from '../app.config';
import { RequestTransactionReportParameters } from '../models/common/request-parameters.model';
import { CardTransactionDetailModel } from '../models/transaction/card-transaction-detail.model';
import { CardTransactionResultModel } from '../models/transaction/card-transaction-result.model';
import { InitiateCardTransactionRequestModel } from '../models/transaction/initiate-card-transaction-request.model';
import { InitiateCardTransactionResponseModel } from '../models/transaction/initiate-card-transaction-response.model';
import { ProcessCardTransactionRequestModel } from '../models/transaction/process-card-transaction-request.model';
import { ProcessCardTransactionResponseModel } from '../models/transaction/process-card-transaction-response.model';
import { RefundCardTransactionRequestModel } from '../models/transaction/refund-card-transaction-request.model';
import { RefundCardTransactionResponseModel } from '../models/transaction/refund-card-transaction-response.model';
import { TransactionReportModel } from '../models/transaction/transaction.model';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(@Inject(DOCUMENT) private document: any,
  private http: HttpClient) { }

  public getTransactionReport(requestTransactionReportParameters: RequestTransactionReportParameters): Observable<TransactionReportModel> {
    return new Observable(subscriber => {
      this.http.post<TransactionReportModel>(config.C2PAPI + '/transaction/report', requestTransactionReportParameters)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: TransactionReportModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getCardTransactionResult(bankReference: string): Observable<CardTransactionResultModel> {
    return new Observable(subscriber => {
      this.http.get<CardTransactionResultModel>(config.C2PAPI + '/transaction/card/search?bankReference=' + bankReference)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: CardTransactionResultModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getCardTransactionDetails(transactionRequestIdentifier: string): Observable<CardTransactionDetailModel> {
    return new Observable(subscriber => {
      this.http.get<CardTransactionDetailModel>(config.C2PAPI + '/transaction/card/get?transactionRequestIdentifier=' + transactionRequestIdentifier)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: CardTransactionDetailModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public initiateCardTransaction(initiateCardTransactionRequestModel: InitiateCardTransactionRequestModel): Observable<InitiateCardTransactionResponseModel> {
    return new Observable(subscriber => {
      this.http.post<InitiateCardTransactionResponseModel>(config.C2PAPI + '/transaction/card/initiate', initiateCardTransactionRequestModel)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: InitiateCardTransactionResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public processCardTransaction(processCardTransactionRequestModel: ProcessCardTransactionRequestModel): Observable<ProcessCardTransactionResponseModel> {
    return new Observable(subscriber => {
      this.http.post<ProcessCardTransactionResponseModel>(config.C2PAPI + '/transaction/card/process', processCardTransactionRequestModel)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: ProcessCardTransactionResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public processRefundCardTransaction(refundCardTransactionRequestModel: RefundCardTransactionRequestModel): Observable<RefundCardTransactionResponseModel> {
    return new Observable(subscriber => {
      this.http.post<RefundCardTransactionResponseModel>(config.C2PAPI + '/transaction/card/refund', refundCardTransactionRequestModel)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: RefundCardTransactionResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

}
