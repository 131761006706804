import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { RequestUserSearchParameters } from 'src/app/core/models/common/request-parameters.model';
import { UserListModel } from 'src/app/core/models/user/user-list.model';
import { UserModel } from 'src/app/core/models/user/user.model';
import { GetUsersAction } from 'src/app/core/store/actions/user.action';
import { UsersState } from 'src/app/core/store/state/users.state';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { UserListDB, USERLISTDB } from 'src/app/shared/tables/list-users';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class ListUserComponent implements OnInit, OnDestroy {
  public userList: UserListModel;
  public users$: Observable<UserModel[]>;

  public tableItem$: Observable<UserListDB[]>;
  public searchText;
  total$: Observable<number>;
  public subscription: Subscription;

  @Select(UsersState.selectedUsers) selectedUsers$: Observable<UserListModel>;
  constructor(public service: TableService, private store: Store) {
    this.searchUserDeactivations();

    this.subscription = new Subscription();
    this.subscription = this.selectedUsers$.subscribe(data => {
      this.userList = data;
      if (this.userList != null) {
        this.users$ = service.tableItem$;
        this.total$ = service.total$;
        this.service.setUserData(this.userList.user);
      }
    });
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  searchUserDeactivations() {
    let requestUserSearchParameters: RequestUserSearchParameters = {
      searchTerm: "",
      limit: 50,
      offset: 0,
      sort: "",
      sortField: ""
    }

    this.store.dispatch(new GetUsersAction(requestUserSearchParameters));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}

