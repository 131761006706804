export class vendorsDB {
    static data = [
        {
            vendor: 'assets/images/team/3.jpg',
            name: "Anna Mull",
            products: "1670",
            store_name: "Zotware",
            date: "8/10/18",
            balance: "R576132",
            revenue: "R9761266",
        },
        {
            vendor: 'assets/images/dashboard/designer.jpg',
            name: "Colton Clay",
            products: "9710",
            store_name: "Green-Plus",
            date: "6/5/18",
            balance: "R780250",
            revenue: "R8793611",
        },
        {
            vendor: 'assets/images/dashboard/boy-2.png',
            name: "Gray Brody",
            products: "579",
            store_name: "Conecom",
            date: "25/2/18",
            balance: "R245508",
            revenue: "R1279520",
        },
        {
            vendor: 'assets/images/dashboard/user.png',
            name: "Lane Skylar",
            products: "8972",
            store_name: "Golddex",
            date: "30/3/18",
            balance: "R7812483",
            revenue: "R8761424",
        },
        {
            vendor: 'assets/images/dashboard/user1.jpg',
            name: "Lane Skylar",
            products: "8678",
            store_name: "Plexzap",
            date: "4/8/18",
            balance: "R89340",
            revenue: "R10285255",
        },
        {
            vendor: 'assets/images/team/1.jpg',
            name: "Paige Turner",
            products: "4680",
            store_name: "Finhigh",
            date: "11/7/18",
            balance: "R87616",
            revenue: "R947611",
        },
        {
            vendor: 'assets/images/dashboard/user3.jpg',
            name: "Perez Alonzo",
            products: "3476",
            store_name: "Betatech",
            date: "17/9/18",
            balance: "R32451",
            revenue: "R647212",
        },
        {
            vendor: 'assets/images/team/2.jpg',
            name: "Petey Cruiser",
            products: "1670",
            store_name: "Warephase",
            date: "8/10/18",
            balance: "R576132",
            revenue: "R9761266",
        },
        {
            vendor: 'assets/images/dashboard/user5.jpg',
            name: "Rowan torres",
            products: "790",
            store_name: "Sunnamplex",
            date: "5/6/18",
            balance: "R87610",
            revenue: "R631479",
        },
        {
            vendor: 'assets/images/dashboard/user2.jpg',
            name: "Woters maxine",
            products: "680",
            store_name: "Kan-code",
            date: "15/4/18",
            balance: "R27910",
            revenue: "R579214",
        },
    ]
}