import { RequestTransactionReportParameters } from "../../models/common/request-parameters.model";
import { CardTransactionDetailModel } from "../../models/transaction/card-transaction-detail.model";
import { CardTransactionResultModel } from "../../models/transaction/card-transaction-result.model";
import { TransactionReportModel } from "../../models/transaction/transaction.model";

export class SelectTransactionReportAction {
    static readonly type = '[Transaction Report] Select';
    constructor(public data : TransactionReportModel) {}
 }
  
 export class GetTransactionReportAction {
    static readonly type = '[Transaction Report] Get by Request Transaction Report Parameters';
    constructor(public requestTransactionReportParameters: RequestTransactionReportParameters) {}
 }

 export class SelectCardTransactionDetailAction {
   static readonly type = '[Card Transaction Detail] Select';
   constructor(public data : CardTransactionDetailModel) {}
}
 
export class GetCardTransactionDetailAction {
   static readonly type = '[Card Transaction Detail] Get by Transaction Request Identifier';
   constructor(public transactionRequestIdentifier: string) {}
}

export class SelectCardTransactionResultAction {
   static readonly type = '[Card Transaction Result] Select';
   constructor(public data : CardTransactionResultModel) {}
}
 
export class GetCardTransactionResultAction {
   static readonly type = '[Card Transaction Result] Get by Bank Reference';
   constructor(public bankReference: string) {}
}