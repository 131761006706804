<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Manage Previous Settlements</h5>
        </div>
        <div class="card-body">
          <div class="custom-datatable">
            <form [formGroup]="previousSettlementForm">
              <div class="row mb-5">
                <div class="col-xl-2 col-sm-4">
                  <div class="input-group input-grp-p">
                    <input class="datepicker-here form-control digits" ngbDatepicker formControlName="startDate"
                      #f="ngbDatepicker" placeholder="Start Date">
                    <div class="input-group-append">
                      <button class="btn calendar" (click)="f.toggle()" type="button"><i
                          class="fa fa-calendar"></i></button>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-sm-4">
                  <div class="input-group input-grp-p">
                    <input class="datepicker-here form-control digits" ngbDatepicker formControlName="endDate"
                      #c="ngbDatepicker" placeholder="End Date">
                    <div class="input-group-append">
                      <button class="btn calendar" (click)="c.toggle()" type="button"><i
                          class="fa fa-calendar"></i></button>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-sm-4">
                  <div class="pull-right">
                    <button type="button" class="btn btn-primary" (click)="searchPreviousSettlements()">Search</button>
                  </div>
                </div>
              </div>
              <!-- <div class="mb-3">
                <input type='text' class="filter-ngx form-control" placeholder='Search...' [(ngModel)]="searchText"
                  autocomplete="off" />
              </div> -->
            </form>
            <br />
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col" sortable="settlementId" (sort)="onSort($event)">Settlement Number</th>
                  <th scope="col" sortable="companyName" (sort)="onSort($event)">Company Name</th>
                  <th scope="col" sortable="batchNumber" (sort)="onSort($event)">Batch Number</th>
                  <th scope="col" sortable="settlementDate" (sort)="onSort($event)">Settlement Date</th>
                  <th scope="col" sortable="amount" (sort)="onSort($event)">Amount</th>
                  <!-- <th scope="col" sortable="payment_status" (sort)="onSort($event)">Payment Status</th>
                  <th scope="col" sortable="order_status" (sort)="onSort($event)">Order Status</th>               -->

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of (settlementItem$ | async)">
                  <th scope="row">{{ item.settlementId }}</th>
                  <th>{{ item.companyName }}</th>
                  <th>{{ item.batchNumber }}</th>
                  <td>{{ item.settlementDate | date:'d MMM yyyy' }}</td>
                  <td>R{{ item.amount | number:'1.2-2' }}</td>
                  <!-- <td><span class='badge {{item.payment_class}}'>{{ item.payment_status }}</span></td>
                  <td><span class='badge {{item.order_class}}'>{{ item.order_status }}</span></td> -->
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-between p-2">
              <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                [pageSize]="service.pageSize">
              </ngb-pagination>
              <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                <option [value]="10" [selected]="true" [ngValue]="2">2 items per page</option>
                <option [ngValue]="4">4 items per page</option>
                <option [ngValue]="6">6 items per page</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->