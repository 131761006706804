export interface TransactionsDB {
    order_id: string;
    transaction_id: string;
    date: string;
    pay_method: string;
    delivery_status: string;
    amount: string;
}

export const TRANSACTIONDB: TransactionsDB[] = [
    {
        order_id: "142",
        transaction_id: "#212145214510",
        date: "Sep 2,19",
        pay_method: "Paypal",
        delivery_status: "Pending",
        amount: "$175/-"
    },
    {
        order_id: "217",
        transaction_id: "#784561421721",
        date: "Dec 10,19",
        pay_method: "Stripe",
        delivery_status: "Failed",
        amount: "$845/-"
    },
    {
        order_id: "546",
        transaction_id: "#476547821142",
        date: "Feb 15,19",
        pay_method: "Stripe",
        delivery_status: "Approved",
        amount: "$314/-"
    },
    {
        order_id: "617",
        transaction_id: "#745384127541",
        date: "Mar 27,19",
        pay_method: "Paypal",
        delivery_status: "Pending",
        amount: "$217/-"
    },
    {
        order_id: "565",
        transaction_id: "#96725125102",
        date: "Sep 1,19",
        pay_method: "Stripe",
        delivery_status: "Failed",
        amount: "$542/-"
    },
    {
        order_id: "754",
        transaction_id: "#547121023651",
        date: "May 18,19",
        pay_method: "Paypal",
        delivery_status: "Pending",
        amount: "$2141/-"
    },
    {
        order_id: "164",
        transaction_id: "#876412242215",
        date: "Jan 14,19",
        pay_method: "Stripe",
        delivery_status: "Approved",
        amount: "$4872/-"
    },
    {
        order_id: "841",
        transaction_id: "#31534221621",
        date: "Apr 22,19",
        pay_method: "Paypal",
        delivery_status: "Failed",
        amount: "$7841/-"
    },
    {
        order_id: "354",
        transaction_id: "#78412457421",
        date: "Mar 26,19",
        pay_method: "Paypal",
        delivery_status: "Pending",
        amount: "$2784/-"
    },
    {
        order_id: "784",
        transaction_id: "#241524757448",
        date: "Feb 29,19",
        pay_method: "Stripe",
        delivery_status: "Approved",
        amount: "$461/-"
    },
    {
        order_id: "142",
        transaction_id: "#212145214510",
        date: "Sep 2,19",
        pay_method: "Paypal",
        delivery_status: "Pending",
        amount: "$147/-"
    },
    {
        order_id: "217",
        transaction_id: "#784561421721",
        date: "Dec 10,19",
        pay_method: "Stripe",
        delivery_status: "Failed",
        amount: "$845/-"
    },
    {
        order_id: "547",
        transaction_id: "#476547821142",
        date: "Feb 15,19",
        pay_method: "Stripe",
        delivery_status: "Approved",
        amount: "$317/-"
    },
    {
        order_id: "671",
        transaction_id: "#745384127541",
        date: "Mar 27,19",
        pay_method: "Paypal",
        delivery_status: "Pending",
        amount: "$217/-"
    },
    {
        order_id: "565",
        transaction_id: "#96725125102",
        date: "Sep 1,19",
        pay_method: "Stripe",
        delivery_status: "Failed",
        amount: "$542/-"
    },
]