<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img src="assets/images/dashboard/designer.jpg" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{ merchant.merchantContactPerson.fullname }}</h5>
                        <span>{{ merchant.merchantContactPerson.email }}</span>
                        <h6 class="f-w-400 mb-0">Mobile: {{ merchant.merchantContactPerson.primaryMobile }}</h6>
                        <h6 class="f-w-400 mb-0">Tel: {{ merchant.merchantContactPerson.secondaryMobile }}</h6>
                    </div>
                    <hr>
                    <div class="project-status">
                        <h5 class="f-w-600">Invoices</h5>
                        <div class="media">
                            <div class="media-body">
                                <h6>Paid<span class="pull-right">80%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-primary" role="progressbar" style="width: 70%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-body">
                                <h6>Oustanding <span class="pull-right">20%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-secondary" role="progressbar" style="width: 20%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-body">
                                <h6>Disputed<span class="pull-right">70%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-danger" role="progressbar" style="width: 10%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card tab2-card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                          <a ngbNavLink><i class="me-2" data-feather="user"></i>Company Details</a>
                          <ng-template ngbNavContent>
                            <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                            aria-labelledby="top-profile-tab">
                            <!-- <h5 class="f-w-600"> Profile</h5> -->
                            <div class="table-responsive profile-table">
                                <table class="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td>Title:</td>
                                            <td>{{ merchant.title }}</td>
                                        </tr>
                                        <tr>
                                            <td>First Name:</td>
                                            <td>{{ merchant.firstname }}</td>
                                        </tr>
                                        <tr>
                                            <td>Last Name:</td>
                                            <td>{{ merchant.lastname }}</td>
                                        </tr>
                                        <tr>
                                            <td>Company Name:</td>
                                            <td>{{ merchant.companyName }}</td>
                                        </tr>
                                        <tr>
                                            <td>Registration Number:</td>
                                            <td>{{ merchant.companyRegistrationNumber }}</td>
                                        </tr>
                                        <tr>
                                            <td>VAT:</td>
                                            <td>{{ merchant.vat }}</td>
                                        </tr> 
                                        <tr>
                                            <td>Identity Number:</td>
                                            <td>{{ merchant.identityNumber }}</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Birth:</td>
                                            <td>{{ merchant.dateOfBirth }}</td>
                                        </tr>
                                        <tr>
                                            <td>Gender:</td>
                                            <td>{{ merchant.gender }}</td>
                                        </tr>
                                        <tr>
                                            <td>Contant Number 1:</td>
                                            <td>{{ merchant.contactNumber1 }}</td>
                                        </tr>     
                                        <tr>
                                            <td>Contant Number 2:</td>
                                            <td>{{ merchant.contactNumber2 }}</td>
                                        </tr>                                  
                                        <tr>
                                            <td>Email:</td>
                                            <td>{{ merchant.email }}</td>
                                        </tr>             
                                        <tr>
                                            <td>Country</td>
                                            <td>South Africa</td>
                                        </tr>
                                        <tr>
                                            <td>Coordinates:</td>
                                            <td>30.5595° S, 22.9375° E</td>
                                        </tr>                                   
                                    </tbody>
                                </table>
                            </div>
                            </div>
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink class="d-flex align-items-center"><i data-feather="briefcase" class="me-2"></i>Bank Account Details</a>
                            <ng-template ngbNavContent>
                                <div class="table-responsive profile-table">
                                    <table class="table table-responsive">
                                        <tbody>
                                            <tr>
                                                <td>Bank Name:</td>
                                                <td>{{ merchant.merchantBankDetails.name }}</td>
                                            </tr>
                                            <tr>
                                                <td>Account Number:</td>
                                                <td>{{ merchant.merchantBankDetails.accNum }}</td>
                                            </tr>
                                            <tr>
                                                <td>Account Type:</td>
                                                <td>{{ merchant.merchantBankDetails.accTypeName }}</td>
                                            </tr>
                                            <tr>
                                                <td>Branch Code:</td>
                                                <td>{{ merchant.merchantBankDetails.branchCode }}</td>
                                            </tr>
                                            <tr>
                                                <td>Swift Code:</td>
                                                <td>N/A</td>
                                            </tr>                                 
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                          </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink class="d-flex align-items-center"><i data-feather="credit-card" class="me-2"></i>Charges & Billing Rates</a>
                            <ng-template ngbNavContent>
                                <div class="table-responsive profile-table">
                                    <table class="table table-responsive">
                                        <tbody>
                                            <tr>
                                                <td>Subscription Fee:</td>
                                                <td>R{{ merchant.merchantRates.subFee | number:'1.2-2' }}</td>
                                            </tr>
                                            <tr>
                                                <td>POS Rental Fee:</td>
                                                <td>R{{ merchant.merchantRates.posRentalFee | number:'1.2-2' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Setup Fee:</td>
                                                <td>R{{ merchant.merchantRates.setupFee | number:'1.2-2' }}</td>
                                            </tr>
                                            <tr>
                                                <td>User Fee:</td>
                                                <td>R0.00</td>
                                            </tr>
                                            <tr>
                                                <td>VISA Credit Card Ecommerce Transaction:</td>
                                                <td>{{ merchant.merchantRates.visaCCEcomTxPercent }}%</td>
                                            </tr>   
                                            <tr>
                                                <td>VISA Debit Card Ecommerce Transaction:</td>
                                                <td>{{ merchant.merchantRates.visaCCEcomTxPercent }}%</td>
                                            </tr> 
                                            <tr>
                                                <td>Mastercard Credit Card Ecommerce Transaction:</td>
                                                <td>{{ merchant.merchantRates.mastercardCCEcomTxPercent }}%</td>
                                            </tr>   
                                            <tr>
                                                <td>Mastercard Debit Card Ecommerce Transaction:</td>
                                                <td>{{ merchant.merchantRates.mastercardDCEcomTxPercent }}%</td>
                                            </tr>   
                                            <tr>
                                                <td>Amex Credit Card Ecommerce Transaction:</td>
                                                <td>{{ merchant.merchantRates.amexCCEcomTxPercent }}%</td>
                                            </tr>  
                                            <tr>
                                                <td>Diners Credit Card Ecommerce Transaction:</td>
                                                <td>{{ merchant.merchantRates.dinersCCEcomTxPercent }}%</td>
                                            </tr>  
                                            <tr>
                                                <td>VISA Credit Card POS Transaction:</td>
                                                <td>{{ merchant.merchantRates.visaCCPOSTxPercent }}%</td>
                                            </tr>   
                                            <tr>
                                                <td>VISA Debit Card POS Transaction:</td>
                                                <td>{{ merchant.merchantRates.visaDCPOSTxPercent }}%</td>
                                            </tr> 
                                            <tr>
                                                <td>Mastercard Credit Card POS Transaction:</td>
                                                <td>{{ merchant.merchantRates.mastercardCCPOSTxPercent }}%</td>
                                            </tr>   
                                            <tr>
                                                <td>Mastercard Debit Card POS Transaction:</td>
                                                <td>{{ merchant.merchantRates.mastercardDCPOSTxPercent }}%</td>
                                            </tr>   
                                            <tr>
                                                <td>Amex Credit Card POS Transaction:</td>
                                                <td>{{ merchant.merchantRates.amexCCPOSTxPercent }}%</td>
                                            </tr>  
                                            <tr>
                                                <td>Diners Credit Card POS Transaction:</td>
                                                <td>{{ merchant.merchantRates.dinersCCPOSTxPercent }}%</td>
                                            </tr>                            
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->