import { BillingInvoiceDetailsModel } from "../../models/billing-invoice/billing-invoice-details.model";
import { BillingInvoiceModel } from "../../models/billing-invoice/billing-invoice.model";

export class SelectBillingInvoiceAction {
    static readonly type = '[Billing Invoice] Select';
    constructor(public data : BillingInvoiceModel) {}
 }
  
 export class GetBillingInvoiceAction {
    static readonly type = '[Billing Invoice] Get by Billing Id';
    constructor(public billingId: number) {}
 }

 export class SelectBillingInvoiceDetailAction {
    static readonly type = '[Billing Invoice Detail] Select';
    constructor(public data : BillingInvoiceDetailsModel) {}
 }
  
 export class GetBillingInvoiceDetailAction {
    static readonly type = '[Billing Invoice Detail] Get by Invoice Number';
    constructor(public invoiceNumber: number) {}
 }