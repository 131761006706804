<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Invoices</h5>
                </div>
                <div class="card-body">
                    <div class="custom-datatable">
                        <form [formGroup]="billingInvoiceForm">
                            <div class="row mb-5">
                                <div class="col-xl-2 col-sm-4">
                                    <input type="text" class="form-control" formControlName="searchText" name="searchText" placeholder="">
                                </div>
                                <div class="col-xl-2 col-sm-4">
                                    <div class="pull-right">
                                        <button type="button" class="btn btn-primary"
                                            (click)="searchBillingInvoice()">Search</button>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="mb-3">
                  <input type='text' class="filter-ngx form-control" placeholder='Search...' [(ngModel)]="searchText"
                    autocomplete="off" />
                </div> -->
                        </form>
                        <br />
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="invoiceNumber" (sort)="onSort($event)">Invoice Number
                                    </th>
                                    <th scope="col" sortable="merchantName" (sort)="onSort($event)">Merchant Name/User
                                        Name</th>
                                    <th scope="col" sortable="subtotal" (sort)="onSort($event)">Total Excl.</th>
                                    <th scope="col" sortable="vat" (sort)="onSort($event)">VAT</th>
                                    <th scope="col" sortable="total" (sort)="onSort($event)">Total Incl.</th>
                                    <th scope="col" sortable="paid" (sort)="onSort($event)">Paid
                                    </th>
                                    <!-- <th scope="col" sortable="order_status" (sort)="onSort($event)">Order Status</th>               -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of (invoices$ | async)">
                                    <th scope="row">{{ item.invoiceNumberPrefix }}{{ item.invoiceNumber }}</th>
                                    <th>{{ item.merchantName }}</th>
                                    <th>R{{ item.subtotal | number:'1.2-2' }}</th>
                                    <td>R{{ item.vat | number:'1.2-2' }}</td>
                                    <td>R{{ item.total | number:'1.2-2' }}</td>
                                    <td><span class='badge badge-success'>{{ item.paid }}</span></td>
                                    <!-- <td><span class='badge {{item.order_class}}'>{{ item.order_status }}</span></td>  -->
                                </tr>
                            </tbody>
                        </table>
                        <!-- <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                [pageSize]="service.pageSize">
                            </ngb-pagination>
                            <select class="form-select" style="width: auto" name="pageSize"
                                [(ngModel)]="service.pageSize">
                                <option [value]="2" [selected]="true" [ngValue]="2">2 items per page</option>
                                <option [ngValue]="4">4 items per page</option>
                                <option [ngValue]="6">6 items per page</option>
                            </select>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->