import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Router } from '@angular/router';
import { MerchantService, SettlementService, TransactionService } from '../../services';
import { PreviousSettlementModel } from '../../models/settlement/previous-settlement.model';
import { GetPreviousSettlementAction, SelectPreviousSettlementAction } from '../actions/settlement.action';
import { TransactionReportModel } from '../../models/transaction/transaction.model';
import { GetTransactionReportAction, SelectTransactionReportAction } from '../actions/transaction.action';

export class TransactionStateModel {
  public transactionReport: TransactionReportModel;
}

@State<TransactionStateModel>({
  name: 'transactionReport',
  defaults: {
    transactionReport: null
  }
})

@Injectable()
export class TransactionState {

    constructor(private _transactionService: TransactionService,
    private _router: Router){
  }

  @Selector()
  public static selectedTransactionReport(state: TransactionStateModel) {
    return state.transactionReport;
  }

  @Action(SelectTransactionReportAction)
  public selectTransactionReportAction(ctx: StateContext<TransactionStateModel>, action: SelectTransactionReportAction) {
    ctx.patchState({ transactionReport: action.data});
  }

  @Action(GetTransactionReportAction)
  public getTransactionReportAction(ctx: StateContext<TransactionStateModel>, action: GetTransactionReportAction) {
    return this._transactionService.getTransactionReport(action.requestTransactionReportParameters).subscribe(data => {
      ctx.patchState({ transactionReport: data});
    })  
  }

}
