import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BillingSubscriptionModel } from 'src/app/core/models/billing-subscription/billing-subscription.model';
import { SubscriptionReportModel } from 'src/app/core/models/billing-subscription/subscription-report.model';
import { RequestLimitParameters } from 'src/app/core/models/common/request-parameters.model';
import { GetBillingInvoiceAction } from 'src/app/core/store/actions/billing-invoice.action';
import { GetBillingSubscriptionAction } from 'src/app/core/store/actions/billing-subscription.action';
import { BillingSubscriptionState } from 'src/app/core/store/state/billing-subscription.state';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { InvoiceDB, INVOICEDB } from 'src/app/shared/tables/invoice';

@Component({
  selector: 'app-billing-subscription',
  templateUrl: './billing-subscription.component.html',
  styleUrls: ['./billing-subscription.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class BillingSubscriptionComponent implements OnInit, OnDestroy {
  public billingSubscriptions: BillingSubscriptionModel;
  public subscriptionReport$: Observable<SubscriptionReportModel[]>
  public subscription: Subscription;
  public searchText;
  total$: Observable<number>;

  @Select(BillingSubscriptionState.selectedBillingSubscription) selectedBillingSubscription$: Observable<BillingSubscriptionModel>;
  constructor(public service: TableService, private store: Store, private router: Router) {
    this.retrieveSubscriptionReport();

    this.subscription = new Subscription();
    this.subscription = this.selectedBillingSubscription$.subscribe(data => {
      this.billingSubscriptions = data;

      if (this.billingSubscriptions != null) {
        this.subscriptionReport$ = service.tableItem$;
        this.total$ = service.total$;
        this.service.setUserData(this.billingSubscriptions.subscriptionReport);
      }
    })

  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  retrieveSubscriptionReport() {
    let requestLimitParams: RequestLimitParameters = {
      limit: 30,
      offset: 0
    }

    this.store.dispatch(new GetBillingSubscriptionAction(requestLimitParams));
  }

  viewInvoice(billingId: number) {
    this.store.dispatch(new GetBillingInvoiceAction(billingId));

    this.router.navigate(['/billing/billing-invoice']);
  }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

