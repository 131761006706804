import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent } from 'src/app/shared/directives/shorting.directive';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from 'src/app/shared/service/table.service';
import { Observable, Subscription } from 'rxjs';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SettlementState } from 'src/app/core/store/state/settlement.state';
import { Select, Store } from '@ngxs/store';
import { PreviousSettlementModel } from 'src/app/core/models/settlement/previous-settlement.model';
import { GetPreviousSettlementAction } from 'src/app/core/store/actions/settlement.action';
import { RequestParameters } from 'src/app/core/models/common/request-parameters.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PreviousSettlementSummaryItem } from 'src/app/core/models/settlement/previous-settlement-summary-item.model';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [TableService, DecimalPipe],
})

export class OrdersComponent implements OnInit, OnDestroy {
  public previousSettlement: PreviousSettlementModel;
  public previousSettlementForm: UntypedFormGroup;
  public closeResult: string;
  public settlementItem$: Observable<PreviousSettlementSummaryItem[]>
  public searchText;
  public subscription: Subscription;
  total$: Observable<number>;

  @Select(SettlementState.selectedPreviousSettlements) selectedPreviousSettlements$: Observable<PreviousSettlementModel>;
  constructor(public service: TableService, private modalService: NgbModal, 
    private store: Store, private formBuilder: UntypedFormBuilder, public datePipe: DatePipe) {
    this.searchPreviousSettlements();

    this.subscription = new Subscription();
    this.subscription = this.selectedPreviousSettlements$.subscribe(data => {
      this.previousSettlement = data;
      if (this.previousSettlement != null) {
        this.settlementItem$ = service.tableItem$;
        this.total$ = service.total$;
        this.service.setUserData(this.previousSettlement.previousSettlementSummary);
      }
    });

    this.createPreviousSettlementForm();
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  createPreviousSettlementForm() {
    this.previousSettlementForm = this.formBuilder.group({
      startDate: [''],
      endDate: ['']
    })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  searchPreviousSettlements() {
    let requestParams: RequestParameters = {
      startDate: '2009-05-01',
      endDate: '2023-06-30',
      limit: 20,
      offset: 0
    };

    this.store.dispatch(new GetPreviousSettlementAction(requestParams));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
