<ng-template class="theme-modal" #apsModal let-modal>
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel">3DS Authentication</h5>
			<button type="button" class="close" data-dismiss="modal" aria-label="Close"
				(click)="modal.dismiss('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body center">
			<iframe class="challengeFrame" name="challengeFrame" id="challengeFrame" frameBorder="0" scrolling="yes" width="100%"></iframe>
		</div>
	</div>
</ng-template>