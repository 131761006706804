import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { CardTransactionDetailModel } from "../../models/transaction/card-transaction-detail.model";
import { TransactionService } from "../../services";
import { GetCardTransactionDetailAction, SelectCardTransactionDetailAction } from "../actions/transaction.action";

export class CardTransactionDetailStateModel {
    public selectedCardTransactionDetail: CardTransactionDetailModel;
  }
  
  @State<CardTransactionDetailStateModel>({
    name: 'cardTransactionDetail',
    defaults: {
        selectedCardTransactionDetail: null
    }
  })
  
  @Injectable()
  export class CardTransactionDetailState {
  
      constructor(private _transactionService: TransactionService,
      private _router: Router){
    }
  
    @Selector()
    public static selectedCardTransactionDetail(state: CardTransactionDetailStateModel) {
      return state.selectedCardTransactionDetail;
    }
  
    @Action(SelectCardTransactionDetailAction)
    public selectCardTransactionDetailAction(ctx: StateContext<CardTransactionDetailStateModel>, action: SelectCardTransactionDetailAction) {
      ctx.patchState({ selectedCardTransactionDetail: action.data});
    }
  
    @Action(GetCardTransactionDetailAction)
    public getCardTransactionDetailAction(ctx: StateContext<CardTransactionDetailStateModel>, action: GetCardTransactionDetailAction) {
      return this._transactionService.getCardTransactionDetails(action.transactionRequestIdentifier).subscribe(data => {
        ctx.patchState({ selectedCardTransactionDetail: data});
      })  
    }
  
  }
  