import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { RequestTransactionReportParameters } from 'src/app/core/models/common/request-parameters.model';
import { TransactionItemModel } from 'src/app/core/models/transaction/transaction-item.model';
import { TransactionReportModel } from 'src/app/core/models/transaction/transaction.model';
import { GetTransactionReportAction } from 'src/app/core/store/actions/transaction.action';
import { TransactionState } from 'src/app/core/store/state/transaction.state';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { TRANSACTIONDB, TransactionsDB } from 'src/app/shared/tables/transactions';

@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class TransactionReportComponent implements OnInit, OnDestroy {

  public searchCriteriaForm: UntypedFormGroup;
  public date: { year: number, month: number };
  public active = 1;

  public transactionReport: TransactionReportModel;
  public transactions$: Observable<TransactionItemModel[]>;
  public subscription: Subscription;
  public searchText;
  total$: Observable<number>;

  @Select(TransactionState.selectedTransactionReport) selectedTransactionReport$: Observable<TransactionReportModel>;

  constructor(private formBuilder: UntypedFormBuilder, public service: TableService, private store: Store) {
    this.retrieveTransactionReport();
    
    this.subscription = new Subscription();
    this.subscription = this.selectedTransactionReport$.subscribe(data => {
      this.transactionReport = data;
      if (this.transactionReport != null && this.transactionReport.totalCount !== 0) {
        this.transactions$ = service.tableItem$;
        this.total$ = service.total$;
        this.service.setUserData(this.transactionReport.transactionDetails);
      }
    });

    this.createSearchCriteriaFormorm();
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  createSearchCriteriaFormorm() {
    this.searchCriteriaForm = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
    })
  }

  retrieveTransactionReport() {
    let requestTransactionReportParameters: RequestTransactionReportParameters = {
      startDate: '2016-01-01',
      endDate: '2024-04-04',
      searchTerm: '',
      searchType: 'all',
      batchNumber: '',
      settlements: false,
      limit: 2050,
      offset: 0,
      sort: "desc",
      sortField: "transactionDate"
    }

    this.store.dispatch(new GetTransactionReportAction(requestTransactionReportParameters));
  }

  ngOnInit() {
   
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
