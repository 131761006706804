import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { UserDeactivationModel } from 'src/app/core/models/user/user-deactivation.model';
import { UserDetailModel } from 'src/app/core/models/user/user-detail.model';
import { GetUserDeactivationAction } from 'src/app/core/store/actions/user.action';
import { UserDeactivationState } from 'src/app/core/store/state/user-deactivation.state';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-user-deactivation',
  templateUrl: './user-deactivation.component.html',
  styleUrls: ['./user-deactivation.component.scss']
})
export class UserDeactivationComponent implements OnInit, OnDestroy {
  public userDeactivation: UserDeactivationModel;
  public user_list = []

  public users$: Observable<UserDetailModel[]>;
  public searchText;
  total$: Observable<number>;
  public subscription: Subscription;

  @Select(UserDeactivationState.selectedUserDeactivations) selectedUserDeactivations$: Observable<UserDeactivationModel>;
  constructor(public service: TableService, private store: Store) {
    this.searchUserDeactivations();

    this.subscription = new Subscription();
    this.subscription = this.selectedUserDeactivations$.subscribe(data => {
      this.userDeactivation = data;
      if (this.userDeactivation != null) {
        this.users$ = service.tableItem$;
        this.total$ = service.total$;
        this.service.setUserData(this.userDeactivation.user);
      }
    });
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }


  searchUserDeactivations() {
    this.store.dispatch(new GetUserDeactivationAction());
  }


  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
