import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { TransactionProcessingRoutingModule } from './transaction-processing-routing.module';
import { ProcessCardTransactionComponent } from './process-card-transaction/process-card-transaction.component';
import { RecurringTransactionsComponent } from './recurring-transactions/recurring-transactions.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    ProcessCardTransactionComponent,
    RecurringTransactionsComponent
  ],
  imports: [
    CommonModule,
    TransactionProcessingRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    NgxDropzoneModule,
    NgxSpinnerModule,
  ]
})
export class TransactionProcessingModule { }
