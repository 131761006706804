import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { DebtorSummaryItemModel } from "../../models/recurring/debtor-summary-item.model";
import { RecurringService } from "../../services";
import { GetDebtorAdministrationSummaryAction, SelectDebtorAdministrationSummaryAction } from "../actions/recurring.action";

export class DebtorAdministrationSummaryStateModel {
    public selectedDebtorAdministrationSummaries: DebtorSummaryItemModel[];
  }
  
  @State<DebtorAdministrationSummaryStateModel>({
    name: 'debtorAdministrationSummary',
    defaults: {
        selectedDebtorAdministrationSummaries: null
    }
  })
  
  @Injectable()
  export class DebtorAdministrationSummaryState {
  
      constructor(private _recurringService: RecurringService,
      private _router: Router){
    }
  
    @Selector()
    public static selectedDebtorAdministrationSummaries(state: DebtorAdministrationSummaryStateModel) {
      return state.selectedDebtorAdministrationSummaries;
    }
  
    @Action(SelectDebtorAdministrationSummaryAction)
    public selectedDebtorAdministrationSummariesAction(ctx: StateContext<DebtorAdministrationSummaryStateModel>, action: SelectDebtorAdministrationSummaryAction) {
      ctx.patchState({ selectedDebtorAdministrationSummaries: action.data});
    }
  
    @Action(GetDebtorAdministrationSummaryAction)
    public getDebtorSummaryTransactionsAction(ctx: StateContext<DebtorAdministrationSummaryStateModel>, action: GetDebtorAdministrationSummaryAction) {
      return this._recurringService.getDebtorAdministrationSummary(action.debtorSummaryTransactionParameters).subscribe(data => {
        ctx.patchState({ selectedDebtorAdministrationSummaries: data});
      })  
    }
  
  }