import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateMerchantComponent } from './merchants/create-merchant/create-merchant.component';
import { MerchantListComponent } from './merchants/merchant-list/merchant-list.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'create-merchant',
                component: CreateMerchantComponent,
                data: {
                    title: "Create Merchant",
                    breadcrumb: "Create Merchant"
                }
            },
            {
                path: 'merchant-list',
                component: MerchantListComponent,
                data: {
                    title: "Merchants",
                    breadcrumb: "Merchants"
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
