import { MerchantModel } from "../../models/merchant/merchant.model";

export class SelectMerchantAction {
    static readonly type = '[Merchant] Select';
    constructor(public data : MerchantModel) {}
 }
  
 export class GetMerchantAction {
    static readonly type = '[Merchant] Get by Id';
    constructor(public merchantId: number) {}
 }
