import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { config } from '../app.config';
import { MerchantModel } from '../models/merchant/merchant.model';

@Injectable()
export class MerchantService {

  constructor(@Inject(DOCUMENT) private document: any,
    private http: HttpClient) { }

  public getMerchantDetails(merchantId: number): Observable<MerchantModel> {
    return new Observable(subscriber => {
      this.http.get<MerchantModel>(config.C2PAPI + '/merchant/get?merchantId=' + merchantId)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: MerchantModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }
}
