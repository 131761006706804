import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Router } from '@angular/router';
import { MerchantModel } from '../../models/merchant/merchant.model';
import { MerchantService } from '../../services';
import { GetMerchantAction, SelectMerchantAction } from '../actions/merchant.action';

export class MerchantStateModel {
  public selectedMerchant: MerchantModel;
}

@State<MerchantStateModel>({
  name: 'merchant',
  defaults: {
    selectedMerchant: null
  }
})

@Injectable()
export class MerchantState {

    constructor(private _merchantService: MerchantService,
    private _router: Router){
  }

  @Selector()
  public static selectedMerchant(state: MerchantStateModel) {
    return state.selectedMerchant;
  }

  @Action(SelectMerchantAction)
  public selectMerchantAction(ctx: StateContext<MerchantStateModel>, action: SelectMerchantAction) {
    ctx.patchState({ selectedMerchant: action.data});
  }

  @Action(GetMerchantAction)
  public getMerchantAction(ctx: StateContext<MerchantStateModel>, action: GetMerchantAction) {
    return this._merchantService.getMerchantDetails(action.merchantId).subscribe(data => {
      ctx.patchState({ selectedMerchant: data});
    })  
  }

}
