import { Component, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { DebtorSummaryTransactionParameters } from 'src/app/core/models/common/request-parameters.model';
import { DebtorSummaryItemModel } from 'src/app/core/models/recurring/debtor-summary-item.model';
import { DebtorSummaryTransactionsModel } from 'src/app/core/models/recurring/debtor-summary-transactions.model';
import { InitiateRecurringTransactionRequestModel } from 'src/app/core/models/recurring/initiate-recurring-transaction-request.model';
import { InitiateRecurringTransactionResponseModel } from 'src/app/core/models/recurring/initiate-recurring-transaction-response.model';
import { RecurringCardTransactionRequestModel } from 'src/app/core/models/recurring/recurring-card-transaction-request.model';
import { RecurringCardTransactionResponseModel } from 'src/app/core/models/recurring/recurring-card-transaction-response.model';
import { RecurringService } from 'src/app/core/services';
import { GetDebtorAdministrationSummaryAction, GetDebtorRecurringSummaryAction, SelectDebtorAdministrationSummaryAction } from 'src/app/core/store/actions/recurring.action';
import { DebtorAdministrationSummaryState } from 'src/app/core/store/state/debtor-administration-summary.state';
import { DebtorSummaryTransactionState } from 'src/app/core/store/state/debtor-summary-transaction.state';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { InvoiceDB } from 'src/app/shared/tables/invoice';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recurring-transactions',
  templateUrl: './recurring-transactions.component.html',
  styleUrls: ['./recurring-transactions.component.scss']
})
export class RecurringTransactionsComponent {
  public recurringCardTransactionForm: UntypedFormGroup;
  public restrictionForm: UntypedFormGroup;
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;
  public active = 1;
  files: File[] = [];

  public tableItem$: Observable<InvoiceDB[]>;
  public debtorSummaryTransactions: DebtorSummaryTransactionsModel;
  public debtorSummaries$: Observable<DebtorSummaryItemModel[]>;
  public debtorAdminSummaries: DebtorSummaryItemModel[] = [];
  public subscription: Subscription;
  public searchText;
  total$: Observable<number>;

  public isLoading: boolean = false;
  public isSubmitted: boolean = false;
  public requestIdentifier: string;
  public selectedCollectionFrequency: string = 'Monthly';

  @Select(DebtorAdministrationSummaryState.selectedDebtorAdministrationSummaries) selectedDebtorAdministrationSummaries$: Observable<DebtorSummaryItemModel[]>;

  constructor(private formBuilder: UntypedFormBuilder, public service: TableService,
    private store: Store, public recurringService: RecurringService, private spinner: NgxSpinnerService) {
    this.retrieveDebtorSummaryTransaction();

    this.subscription = new Subscription();
    this.subscription = this.selectedDebtorAdministrationSummaries$.subscribe(data => {
        this.store.dispatch(new SelectDebtorAdministrationSummaryAction(data));
        this.debtorSummaries$ = service.tableItem$;
        this.total$ = service.total$;
        this.service.setUserData(data);
    });

    this.initiateRecurringTransactionForm();
    this.createRestrictionForm();
  }

  initiateRecurringTransactionForm() {
    this.recurringCardTransactionForm = this.formBuilder.group({
      debtorName: [''],
      debtorSurname: [''],
      debtorContactNumber: [''],
      debtorEmailAddress: [''],
      debtorIdentifier: [''],
      cardNumber: [''],
      expiryMonth: [''],
      expiryYear: [''],
      cvv: [''],
      collectionFrequency: [''],
      instalmentDay: [''],
      instalmentAmount: [''],
      instalmentCount: [''],
      collectionAutoRerun: [''],
      dynamicPayday: [''],
      earlyDecemberCollection: [''],
    });
  }

  get recurringCardTx() {
    return this.recurringCardTransactionForm.controls;
  }

  createRestrictionForm() {
    this.restrictionForm = this.formBuilder.group({
      products: [''],
      category: [''],
      min: [''],
      max: ['']
    })
  }

  retrieveDebtorSummaryTransaction() {
    let debtorSummaryTransactionParameters: DebtorSummaryTransactionParameters = {
      userId: 1050,
      startDate: '2024-03-25',
      endDate: '2024-04-10',
    }

    this.store.dispatch(new GetDebtorAdministrationSummaryAction(debtorSummaryTransactionParameters));
  }

  initiateRecurringTransaction() {
    this.isSubmitted = true;
    this.isLoading = true;
  
    this.spinner.show();
  
    const initiateRecurringTransactionRequestModel = new InitiateRecurringTransactionRequestModel();
    const recurringCardTx = this.recurringCardTx;
  
    initiateRecurringTransactionRequestModel.debtorName = recurringCardTx.debtorName.value;
    initiateRecurringTransactionRequestModel.debtorSurname = recurringCardTx.debtorSurname.value;
    initiateRecurringTransactionRequestModel.debtorContactNumber = "" + recurringCardTx.debtorContactNumber.value;
    initiateRecurringTransactionRequestModel.debtorEmailAddress = recurringCardTx.debtorEmailAddress.value;
    initiateRecurringTransactionRequestModel.debtorIdentifier = recurringCardTx.debtorIdentifier.value;
    initiateRecurringTransactionRequestModel.cardNumber = "" + recurringCardTx.cardNumber.value;
    initiateRecurringTransactionRequestModel.cardExpiryDate = recurringCardTx.expiryMonth.value.toString().padStart(2, '0') + "" + recurringCardTx.expiryYear.value;
    initiateRecurringTransactionRequestModel.cardCVV = recurringCardTx.cvv.value.toString().padStart(3, '0');
    initiateRecurringTransactionRequestModel.collectionFrequency = recurringCardTx.collectionFrequency.value;
    initiateRecurringTransactionRequestModel.instalmentDay = parseInt(recurringCardTx.instalmentDay.value);
    initiateRecurringTransactionRequestModel.instalmentAmount = parseFloat(recurringCardTx.instalmentAmount.value);
    initiateRecurringTransactionRequestModel.instalmentCount = parseInt(recurringCardTx.instalmentCount.value);
    initiateRecurringTransactionRequestModel.collectionAutoRerun = recurringCardTx.collectionAutoRerun.value || false;
    initiateRecurringTransactionRequestModel.allowDynamicPayday = recurringCardTx.dynamicPayday.value || false;
    initiateRecurringTransactionRequestModel.earlyDecemberCollection = recurringCardTx.earlyDecemberCollection.value || false;;

    this.recurringService.initiateRecurringTransaction(initiateRecurringTransactionRequestModel).subscribe({
      next: (data: InitiateRecurringTransactionResponseModel) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.requestIdentifier = data.requestIdentifier;
        this.clearRecurringForm();
        this.spinner.hide();
        Swal.fire({
          icon: "success",
          title: "Transaction Initiated!",
          text: "An email has been sent out to the customer."
        });
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorResponse
        });
      }
    });
  }

  clearRecurringForm() {
    this.recurringCardTransactionForm.setValue({
      debtorName: "",
      debtorSurname: "",
      debtorContactNumber: "",
      debtorEmailAddress: "",
      debtorIdentifier: "",
      cardNumber: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: "",
      collectionFrequency: "",
      instalmentDay: "",
      instalmentAmount: "",
      instalmentCount: "",
      collectionAutoRerun: "",
      dynamicPayday: "",
      earlyDecemberCollection: ""
    });
  }

  processRecurringTransaction(requestIdentifier: string) {
    let recurringCardTransactionRequestModel: RecurringCardTransactionRequestModel = {
      requestIdentifier: requestIdentifier,
      amount: 100
    }

    this.recurringService.processRecurringTransaction(recurringCardTransactionRequestModel).subscribe({
      next: (data: RecurringCardTransactionResponseModel) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
        Swal.fire({
          icon: "success",
          title: "Transaction Successful!",
          text: "Request successfully processed by Click2Pay Payment Engine."
        });
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
        Swal.fire({
          icon: "error",
          title: "Transaction Failed!",
          text: errorResponse
        });
      }
    })
  }
  
  onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
