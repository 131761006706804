import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MerchantModel } from 'src/app/core/models/merchant/merchant.model';
import { GetMerchantAction } from 'src/app/core/store/actions/merchant.action';
import { MerchantState } from 'src/app/core/store/state/merchant.state';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public merchant: MerchantModel;
  public active = 1;

  @Select(MerchantState.selectedMerchant) selectedMerchant$: Observable<MerchantModel>;
  constructor(private store: Store) { 
    this.selectedMerchant$.subscribe(data => {
      this.merchant = data;
    });
  }

  ngOnInit() { }

}
