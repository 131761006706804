import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-merchant',
  templateUrl: './create-merchant.component.html',
  styleUrls: ['./create-merchant.component.scss']
})
export class CreateMerchantComponent {
  public generalForm: UntypedFormGroup;
  public restrictionForm: UntypedFormGroup;
  public settingsAndBankSetupForm: UntypedFormGroup;
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;
  public active = 1;

  constructor(private formBuilder: UntypedFormBuilder, private calendar: NgbCalendar) {
    this.createGeneralForm();
    this.createRestrictionForm();
    this.createSettingsAndBankSetupForm();
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  createGeneralForm() {
    this.generalForm = this.formBuilder.group({
      name: [''],
      code: [''],
      start_date: [''],
      end_date: [''],
      free_shipping: [''],
      quantity: [''],
      discount_type: [''],
      status: [''],
    });
  }

  createRestrictionForm() {
    this.restrictionForm = this.formBuilder.group({
      products: [''],
      category: [''],
      min: [''],
      max: ['']
    })
  }

  createSettingsAndBankSetupForm() {
    this.settingsAndBankSetupForm = this.formBuilder.group({
      active: [''],
      settleUsersDirectly: [''],
      requireBatchSettlementReport: [''],
      contractStartDate: [''],
      contractEndDate: [''],
      merchantId: ['']
    })
  }

  ngOnInit() {

  }

}
