import { NgModule, Optional, SkipSelf } from '@angular/core';

import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';

import { services } from './services';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { TokenInterceptor } from './interceptor/token.inteceptor';
import { NgxsModule } from '@ngxs/store';
import { MerchantState } from './store/state/merchant.state';
import { environment } from 'src/environments/environment';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { SettlementState } from './store/state/settlement.state';
import { BillingSubscriptionState } from './store/state/billing-subscription.state';
import { BillingInvoiceState } from './store/state/billing-invoice.state';
import { BillingInvoiceDetailState } from './store/state/billing-invoice-detail.state';
import { TransactionState } from './store/state/transaction.state';
import { UserDeactivationState } from './store/state/user-deactivation.state';
import { UsersState } from './store/state/users.state';
import { CardTransactionDetailState } from './store/state/card-transaction-detail.state';
import { DebtorSummaryTransactionState } from './store/state/debtor-summary-transaction.state';
import { CardTransactionResultState } from './store/state/card-transaction-result.state';
import { DebtorAdministrationSummaryState } from './store/state/debtor-administration-summary.state';
import { RecurringPaymentTransactionReportState } from './store/state/recurring-payment-transaction-report.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot(
      [
        BillingInvoiceState,
        BillingInvoiceDetailState,
        BillingSubscriptionState,
        CardTransactionDetailState,
        CardTransactionResultState,
        DebtorAdministrationSummaryState,
        DebtorSummaryTransactionState,
        MerchantState,
        RecurringPaymentTransactionReportState,
        SettlementState,
        TransactionState,
        UserDeactivationState,
        UsersState
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          suppressErrors: false,
          injectContainerState: false
        }
      }
    ),
    // devtools always last
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        'billingInvoices', 
        'billingInvoiceDetail', 
        'billingSubscriptions', 
        'cardTransactionDetail',
        'cardTransactionResult',
        'debtorAdministrationSummary',
        'debtorSummaryTransactions',
        'merchant', 
        'previousSettlement', 
        'recurringPaymentTransactionReport',
        'transactionReport', 
        'userDeactivations', 
        'users'],
    }),
  ],
  exports: [],
  providers: [
    ...services,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})


export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

}
