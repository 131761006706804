<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Merchant Administration</h5>
        </div>
        <div class="card-body tab2-card">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Merchant Profile</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="generalForm" class="needs-validation" novalidate="">
                            <div class="row">
                                <div class="col-sm-5">
                                    <h4>Merchant Details</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Merchant Name</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Company Reg</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                VAT Number</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label
                                                for="validationCustom1"><span>*</span>Title</label></div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" required="">
                                                <option value="">--Select Title--</option>
                                                <option value="1">Male</option>
                                                <option value="2">Female</option>
                                                <option value="2">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                First Name</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Last Name</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Identity Number</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Date of Birth</label>
                                        </div>
                                        <div class="col-md-7 ">
                                            <div class="input-group input-grp-p">
                                                <input class="datepicker-here form-control digits" ngbDatepicker
                                                    formControlName="start_date" #f="ngbDatepicker">
                                                <div class="input-group-append">
                                                    <button class="btn calendar" (click)="f.toggle()" type="button"><i
                                                            class="fa fa-calendar"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label>Gender</label></div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" required="">
                                                <option value="">--Select Gender--</option>
                                                <option value="1">Male</option>
                                                <option value="2">Female</option>
                                                <option value="2">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Contact No. 1</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Contact No. 2</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Email Address</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <h4>Bank Account Details</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom1"><span>*</span>Bank
                                                Name</label></div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" required="">
                                                <option value="">--Select Bank--</option>
                                                <option value="1">ABSA</option>
                                                <option value="2">African Bank</option>
                                                <option value="2">Bidvest Bank</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Account Number</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label
                                                for="validationCustom1"><span>*</span>Account Type</label></div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" required="">
                                                <option value="">--Select Account Type--</option>
                                                <option value="1">Cheque/Current</option>
                                                <option value="2">Savings</option>
                                                <option value="2">Transmission</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>SWIFT Code</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <h4>Merchant Contact Person</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Full Name</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Telephone</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Mobile</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Email Address</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="code" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="pull-right">
                            <button type="button" class="btn btn-primary">Next</button>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>Charges & Billing Rates</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="restrictionForm" class="needs-validation" novalidate="">
                            <div class="row">
                                <div class="col-sm-5">
                                    <h4>Transaction Rates</h4>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="validationCustom3">VISA Credit Card
                                                Ecommerce</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="validationCustom3">VISA Debit Card
                                                Ecommerce</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="validationCustom3">Mastercard Credit Card
                                                Ecommerce</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="validationCustom3">Mastercard Debit Card
                                                Ecommerce</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="validationCustom3">Amex Credit Card
                                                Ecommerce</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="validationCustom3">Diners Credit Card
                                                Ecommerce</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="validationCustom3">VISA Credit Card
                                                POS</label>
                                        </div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="validationCustom3">VISA Debit Card
                                                POS</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="validationCustom3">Mastercard Credit Card
                                                POS</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="validationCustom3">Mastercard Debit Card
                                                POS</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="validationCustom3">Amex Credit Card
                                                POS</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="validationCustom3">Diners Credit Card
                                                POS</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <h4>Fees</h4>
                                    <div class="form-group row">
                                        <div class="col-md-3"><label for="validationCustom3">Subscription Fee</label>
                                        </div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-3"><label for="validationCustom3">POS Rental Fee</label>
                                        </div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-3"><label for="validationCustom3">Setup Fee</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-3"><label for="validationCustom3">User Fee</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="products"
                                                id="validationCustom3" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Product Name.</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="pull-right">
                            <button type="button" class="btn btn-primary">Next</button>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink>Settings & Bank Setup</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="settingsAndBankSetupForm" class="needs-validation" novalidate="">
                            <div class="row">
                                <div class="col-sm-5">
                                    <h4>Merchant Settings</h4>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox-primary-1" type="checkbox" formControlName="active"
                                                    data-original-title="" title="">
                                                <label for="checkbox-primary-1">Active</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox-primary-1" type="checkbox"
                                                    formControlName="settleUsersDirectly" data-original-title=""
                                                    title="">
                                                <label for="checkbox-primary-1">Settle Users Directly</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox-primary-1" type="checkbox"
                                                    formControlName="requireBatchSettlementReport"
                                                    data-original-title="" title="">
                                                <label for="checkbox-primary-1">Requires Batch Settlement Report</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-4">
                                            <label>Contract Start Date</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-group input-grp-p">
                                                <input class="datepicker-here form-control digits" ngbDatepicker
                                                    formControlName="contractStartDate" #f="ngbDatepicker">
                                                <div class="input-group-append">
                                                    <button class="btn calendar" (click)="f.toggle()" type="button"><i
                                                            class="fa fa-calendar"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-4">
                                            <label>Contract End Date</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-group input-grp-p">
                                                <input class="datepicker-here form-control digits" ngbDatepicker
                                                    formControlName="contractEndDate" #c="ngbDatepicker">
                                                <div class="input-group-append">
                                                    <button class="btn calendar" (click)="c.toggle()" type="button"><i
                                                            class="fa fa-calendar"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h4>Billing Settings</h4>
                                    <div class="row mb-3">
                                        <div class="col-xl-8 col-sm-10">
                                            <div
                                                class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label class="d-block" for="edo-ani1">
                                                    <input class="radio_animated" id="edo-ani1" type="radio"
                                                        name="rdo-ani">
                                                    Bill merchant primary sub and users smses
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-xl-12 col-sm-14">
                                            <div
                                                class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label class="d-block" for="edo-ani1">
                                                    <input class="radio_animated" id="edo-ani1" type="radio"
                                                        name="rdo-ani">
                                                    Bill merchant primary sub, user sub account and smses
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-xl-12 col-sm-14">
                                            <div
                                                class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label class="d-block" for="edo-ani1">
                                                    <input class="radio_animated" id="edo-ani1" type="radio"
                                                        name="rdo-ani">
                                                    Bill merchant primary sub and users user sub account and smses
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <h4>Bank Setup</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Merchant ID</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="merchantId" id="validationCustom1"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-xl-3 col-sm-4">
                                            <label>Merchant Type</label>
                                        </div>
                                        <div class="col-xl-9 col-sm-8">
                                            <div
                                                class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label class="d-block" for="edo-ani3">
                                                    <input class="radio_animated" id="edo-ani3" type="radio"
                                                        name="rdo-ani1">
                                                    ISO
                                                </label>
                                                <label class="d-block" for="edo-ani4">
                                                    <input class="radio_animated" id="edo-ani4" type="radio"
                                                        name="rdo-ani1" checked="">
                                                    TPPP
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="pull-right">
                            <button type="button" class="btn btn-primary">Save</button>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->