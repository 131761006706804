import { BillingSubscriptionModel } from "../../models/billing-subscription/billing-subscription.model";
import { RequestLimitParameters } from "../../models/common/request-parameters.model";

export class SelectBillingSubscriptionAction {
    static readonly type = '[Billing Subscriptions] Select';
    constructor(public data : BillingSubscriptionModel) {}
 }
  
 export class GetBillingSubscriptionAction {
    static readonly type = '[Billing Subscriptions] Get by Request Limit Parameters';
    constructor(public requestLimitParameters: RequestLimitParameters) {}
 }
