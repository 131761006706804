import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { config } from '../app.config';
import { BillingSubscriptionModel } from '../models/billing-subscription/billing-subscription.model';
import { RequestLimitParameters } from '../models/common/request-parameters.model';

@Injectable({
  providedIn: 'root'
})
export class BillingsubscriptionService {

  constructor(@Inject(DOCUMENT) private document: any,
  private http: HttpClient) { }

  public getBillingSubscriptions(requestLimitParameters: RequestLimitParameters): Observable<BillingSubscriptionModel> {
    return new Observable(subscriber => {
      this.http.post<BillingSubscriptionModel>(config.C2PAPI + '/subscription/report', requestLimitParameters)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: BillingSubscriptionModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }
}
