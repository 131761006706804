import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxSpinnerModule } from "ngx-spinner";
import { SharedModule } from "src/app/shared/shared.module";
import { CustomerPaymentFormComponent } from './customer-payment-form/customer-payment-form.component';
import { CustomerRoutingModule } from "./customer-routing.module";
import { CustomerPeachPaymentFormComponent } from './customer-peach-payment-form/customer-peach-payment-form.component';
import { CustomerPaymentStatusComponent } from './customer-payment-status/customer-payment-status.component';

@NgModule({
    declarations: [
    CustomerPaymentFormComponent,
    CustomerPeachPaymentFormComponent,
    CustomerPaymentStatusComponent
  ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      CustomerRoutingModule,
      CarouselModule,
      SharedModule,
      NgbModule,
      NgxSpinnerModule,
    ]
  })
  export class CustomerModule { }
  