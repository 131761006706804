import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Router } from '@angular/router';
import { BillingInvoiceService } from '../../services';
import { BillingInvoiceModel } from '../../models/billing-invoice/billing-invoice.model';
import { GetBillingInvoiceAction, GetBillingInvoiceDetailAction, SelectBillingInvoiceAction, SelectBillingInvoiceDetailAction } from '../actions/billing-invoice.action';
import { BillingInvoiceDetailsModel } from '../../models/billing-invoice/billing-invoice-details.model';

export class BillingInvoiceStateModel {
    public billingInvoice: BillingInvoiceModel;
}

@State<BillingInvoiceStateModel>({
    name: 'billingInvoice',
    defaults: {
        billingInvoice: null
    }
})

@Injectable()
export class BillingInvoiceState {

    constructor(private _billingInvoiceService: BillingInvoiceService,
        private _router: Router) {
    }

    @Selector()
    public static selectedBillingInvoice(state: BillingInvoiceStateModel) {
        return state.billingInvoice;
    }

    @Action(SelectBillingInvoiceAction)
    public selectBillingInvoiceAction(ctx: StateContext<BillingInvoiceStateModel>, action: SelectBillingInvoiceAction) {
        ctx.patchState({ billingInvoice: action.data });
    }

    @Action(GetBillingInvoiceAction)
    public getBillingInvoiceAction(ctx: StateContext<BillingInvoiceStateModel>, action: GetBillingInvoiceAction) {
        return this._billingInvoiceService.getBillingInvoices(action.billingId).subscribe(data => {
            ctx.patchState({ billingInvoice: data });
        })
    }

}
