import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { config } from '../app.config';
import { RequestParameters } from '../models/common/request-parameters.model';
import { PreviousSettlementModel } from '../models/settlement/previous-settlement.model';

@Injectable({
  providedIn: 'root'
})
export class SettlementService {

  constructor(@Inject(DOCUMENT) private document: any,
  private http: HttpClient) { }

  public getPreviousSettlement(requestParameters: RequestParameters): Observable<PreviousSettlementModel> {
    return new Observable(subscriber => {
      this.http.post<PreviousSettlementModel>(config.C2PAPI + '/settlement/previous_settlements', requestParameters)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: PreviousSettlementModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }
}
