<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Transactions</h5>
                </div>
                <div class="card-body tab2-card">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>Search Criteria</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="searchCriteriaForm" class="needs-validation user-add" novalidate="">
                                    <div class="permission-block">
                                        <div class="attribute-blocks">
                                            <h5 class="f-w-600 mb-3">Filters</h5>
                                            <div class="row mb-3">
                                                <div class="col-xl-4 col-sm-6">
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="edo-ani1">
                                                            <input class="radio_animated" id="edo-ani1" type="radio"
                                                                name="filter" checked>
                                                            All Users
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-xl-4 col-sm-6">
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="edo-ani1">
                                                            <input class="radio_animated" id="edo-ani1" type="radio"
                                                                name="filter">
                                                            By User Firstname, Lastname or MSISDN
                                                        </label>
                                                    </div>
                                                    <input class="form-control" id="validationCustom0" type="text"
                                                        required="">
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-xl-4 col-sm-6">
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="edo-ani1">
                                                            <input class="radio_animated" id="edo-ani1" type="radio"
                                                                name="filter">
                                                            By Batch
                                                        </label>
                                                    </div>
                                                    <input class="form-control" id="validationCustom0" type="text"
                                                        required="">
                                                </div>
                                            </div>
                                            <h5 class="f-w-600 mb-3">Date Range</h5>
                                            <div class="row mb-3">
                                                <div class="col-xl-4 col-sm-6">
                                                    <div class="input-group input-grp-p">
                                                        <input class="datepicker-here form-control digits" ngbDatepicker
                                                            formControlName="start_date" #f="ngbDatepicker">
                                                        <div class="input-group-append">
                                                            <button class="btn calendar" (click)="f.toggle()"
                                                                type="button"><i class="fa fa-calendar"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-sm-6">
                                                    <div class="input-group input-grp-p">
                                                        <input class="datepicker-here form-control digits" ngbDatepicker
                                                            formControlName="end_date" #c="ngbDatepicker">
                                                        <div class="input-group-append">
                                                            <button class="btn calendar" (click)="c.toggle()"
                                                                type="button"><i class="fa fa-calendar"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 class="f-w-600 mb-3">Transaction Type</h5>
                                            <div class="row mb-3">
                                                <div class="col-xl-4 col-sm-6">
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="all">
                                                            <input class="radio_animated" id="all" type="radio"
                                                                name="transaction-type" checked>
                                                            All Transactions
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="approved">
                                                            <input class="radio_animated" id="approved" type="radio"
                                                                name="transaction-type">
                                                            Approved only
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="declined">
                                                            <input class="radio_animated" id="declined" type="radio"
                                                                name="transaction-type">
                                                            Declined only
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="refund">
                                                            <input class="radio_animated" id="refund" type="radio"
                                                                name="transaction-type">
                                                            Refunded only
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="chargeback">
                                                            <input class="radio_animated" id="chargeback" type="radio"
                                                                name="transaction-type">
                                                            Chargeback only
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="manual">
                                                            <input class="radio_animated" id="manual" type="radio"
                                                                name="transaction-type">
                                                            Manual Authorisation only
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 class="f-w-600 mb-3">Report Type</h5>
                                            <div class="row mb-3">
                                                <div class="col-xl-4 col-sm-6">
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="edo-ani1">
                                                            <input class="radio_animated" id="edo-ani1" type="radio"
                                                                name="report-type" checked>
                                                            Transactions
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="edo-ani1">
                                                            <input class="radio_animated" id="edo-ani1" type="radio"
                                                                name="report-type">
                                                            Consolidated Settlements
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="edo-ani1">
                                                            <input class="radio_animated" id="edo-ani1" type="radio"
                                                                name="report-type">
                                                            Batch Settlements
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="pull-right">
                                    <button type="button" class="btn btn-primary">Search</button>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>Report</a>
                            <ng-template ngbNavContent>
                                <div class="btn-popup pull-right">
                                    <a class="btn btn-primary" (click)="retrieveTransactionReport()">Refresh</a>
                                </div>
                                <div id="transactionReport" class="category-table custom-datatable transcation-datatable">
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col" sortable="merchantName" (sort)="onSort($event)">
                                                        Merchant Name
                                                    </th>
                                                    <th scope="col" sortable="user" (sort)="onSort($event)">User</th>
                                                    <th scope="col" sortable="uniqueId" (sort)="onSort($event)">Unique
                                                        ID</th>
                                                    <th scope="col" sortable="batchNumber" (sort)="onSort($event)">
                                                        Batch</th>
                                                    <th scope="col" sortable="date" (sort)="onSort($event)">Date</th>
                                                    <th scope="col" sortable="cardTypeDescription"
                                                        (sort)="onSort($event)">Card Brand</th>
                                                    <th scope="col" sortable="cardNumber" (sort)="onSort($event)">Card
                                                        Number</th>
                                                    <th scope="col" sortable="bankReference" (sort)="onSort($event)">
                                                        Bank Reference</th>
                                                    <th scope="col" sortable="bankResponse" (sort)="onSort($event)">
                                                        Bank Response</th>
                                                    <th scope="col" sortable="transactionType" (sort)="onSort($event)">
                                                        Transaction Type</th>
                                                    <th scope="col" sortable="userReference" (sort)="onSort($event)">
                                                        User Reference</th>
                                                    <th scope="col" sortable="amount" (sort)="onSort($event)">Amount
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of transactions$ | async ">
                                                    <td>{{ item.merchantName }}</td>
                                                    <td>{{ item.user }}</td>
                                                    <td>{{ item.uniqueId }}</td>
                                                    <td>{{ item.batchNumber }}</td>
                                                    <td>{{ item.transactionDate | date:'yyyy-MM-dd hh:mm:ss a' }}</td>
                                                    <td>{{ item.cardTypeDescription }}</td>
                                                    <td>{{ item.cardNumber }}</td>
                                                    <td>{{ item.bankReference }}</td>
                                                    <td>{{ item.bankResponse }}</td>
                                                    <td>{{ item.transactionType }}</td>
                                                    <td>{{ item.userReference }}</td>
                                                    <td>R{{ item.amount | number:'1.2-2' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="d-flex justify-content-center p-2">
                                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                                [pageSize]="service.pageSize" [maxSize]="10">
                                            </ngb-pagination>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->