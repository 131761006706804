import { Component } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { CardTransactionResultModel } from 'src/app/core/models/transaction/card-transaction-result.model';
import { InitiateCardTransactionRequestModel } from 'src/app/core/models/transaction/initiate-card-transaction-request.model';
import { InitiateCardTransactionResponseModel } from 'src/app/core/models/transaction/initiate-card-transaction-response.model';
import { RefundCardTransactionRequestModel } from 'src/app/core/models/transaction/refund-card-transaction-request.model';
import { RefundCardTransactionResponseModel } from 'src/app/core/models/transaction/refund-card-transaction-response.model';
import { TransactionService } from 'src/app/core/services';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-process-card-transaction',
  templateUrl: './process-card-transaction.component.html',
  styleUrls: ['./process-card-transaction.component.scss']
})
export class ProcessCardTransactionComponent {
  public cardTransactionForm: FormGroup;
  public transactionResultForm: UntypedFormGroup;
  public refundTransactionForm: UntypedFormGroup;
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;
  public active = 1;
  public transactionRequestIdentifier: string;

  public cardTransactionResult: CardTransactionResultModel; 

  public isLoading: boolean = false;
  public isSubmitted: boolean = false;
  public showTransactionResult: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, public transactionService: TransactionService,
    private spinner: NgxSpinnerService) {
    this.initiateCardTransactionForm();
    this.refundTransactionResultForm();
    this.transactionRefundForm();
  }

  initiateCardTransactionForm() : FormGroup {
    return this.cardTransactionForm = this.formBuilder.group({
      transactionReference: new FormControl(""),
      transactionAmount: new FormControl(""),
      cardHolderName: new FormControl(""),
      mobile: new FormControl(""),
      email: new FormControl("")
    })
  }

  get cardTx() {
    return this.cardTransactionForm.controls;
  }

  refundTransactionResultForm() {
    return this.transactionResultForm = this.formBuilder.group({
      bankReference: new FormControl("")
    });
  }

  get refundCardTx() {
    return this.transactionResultForm.controls;
  }

  transactionRefundForm() {
    return this.refundTransactionForm = this.formBuilder.group({
      amountToRefund: new FormControl("")
    });
  }

  get transactionRefund() {
    return this.refundTransactionForm.controls;
  }

  initiateCardTransaction() {
    this.isSubmitted = true;
    this.isLoading = true;

    this.spinner.show();

    const initiateCardTransactionRequestModel = new InitiateCardTransactionRequestModel();
    const cardTx = this.cardTx;
    
    initiateCardTransactionRequestModel.transactionReference = cardTx.transactionReference.value;
    initiateCardTransactionRequestModel.transactionAmount = parseInt(cardTx.transactionAmount.value);
    initiateCardTransactionRequestModel.cardHolderName = cardTx.cardHolderName.value;
    initiateCardTransactionRequestModel.mobile = "" + cardTx.mobile.value;
    initiateCardTransactionRequestModel.email = cardTx.email.value;
    initiateCardTransactionRequestModel.recurringTransaction = false;
    
    this.transactionService.initiateCardTransaction(initiateCardTransactionRequestModel).subscribe({
      next: (data: InitiateCardTransactionResponseModel) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.transactionRequestIdentifier = data.transactionRequestIdentifier;
        this.clearForm();
        this.spinner.hide();
        Swal.fire({
          icon: "success",
          title: "Transaction Initiated!",
          text: "An email has been sent out to the customer."
        });
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorResponse
        });
      }
    });
  }

  retrieveCardTransactionResult() {
    this.isSubmitted = true;
    this.isLoading = true;

    this.spinner.show();

    let bankReference = this.refundCardTx.bankReference.value;

    if (bankReference !== '')
    {
      this.transactionService.getCardTransactionResult(bankReference).subscribe({
        next: (data: CardTransactionResultModel) => {
          this.cardTransactionResult = data;
          this.showTransactionResult = true;
          this.isLoading = false;
          this.isSubmitted = false;
          this.spinner.hide();     
        },
        error: (errorResponse: any) => {
          this.isLoading = false;
          this.isSubmitted = false;
          this.spinner.hide();
        }
      })
    }
  }

  refundCardTranaction() {
    this.isSubmitted = true;
    this.isLoading = true;

    this.spinner.show();
    
    const refundCardTransactionRequestModel = new RefundCardTransactionRequestModel();
    const refundCardTx = this.transactionRefund;
    
    refundCardTransactionRequestModel.transactionReference = this.cardTransactionResult.transactionReference;
    refundCardTransactionRequestModel.transactionRefundAmount = parseFloat(refundCardTx.amountToRefund.value);
    refundCardTransactionRequestModel.currency = "ZAR"
    
    this.transactionService.processRefundCardTransaction(refundCardTransactionRequestModel).subscribe({
      next: (data: RefundCardTransactionResponseModel) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.clearRefundForm();
        this.spinner.hide();
        Swal.fire({
          icon: "success",
          title: "Successful!",
          text: "Refund has successfully been processed."
        });
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
        Swal.fire({
          icon: "error",
          title: "Refund Transaction Failed.!",
          text: errorResponse
        });
      }
    });
  }

  clearForm() {
    this.cardTransactionForm.setValue({
      transactionReference: "",
      transactionAmount: "",
      cardHolderName: "",
      mobile: "",
      email: ""
    });
  }

  clearRefundForm() {
    this.refundTransactionForm.setValue({
      amountToRefund: ""
    });
  }

}
