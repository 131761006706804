import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { RecurringPaymentTransactionReportModel } from "../../models/recurring/recurring-payment-transaction-report.model";
import { RecurringService } from "../../services";
import { GetRecurringPaymentTransactionReportAction, SelectRecurringPaymentTransactionReportAction } from "../actions/recurring.action";

export class RecurringPaymentTransactionReportStateModel {
    public selectedRecurringPaymentTransactionReport: RecurringPaymentTransactionReportModel[];
  }
  
  @State<RecurringPaymentTransactionReportStateModel>({
    name: 'recurringPaymentTransactionReport',
    defaults: {
        selectedRecurringPaymentTransactionReport: null
    }
  })
  
  @Injectable()
  export class RecurringPaymentTransactionReportState {
  
      constructor(private _recurringService: RecurringService,
      private _router: Router){
    }
  
    @Selector()
    public static selectedRecurringPaymentTransactionReport(state: RecurringPaymentTransactionReportStateModel) {
      return state.selectedRecurringPaymentTransactionReport;
    }
  
    @Action(SelectRecurringPaymentTransactionReportAction)
    public selectedRecurringPaymentTransactionReportAction(ctx: StateContext<RecurringPaymentTransactionReportStateModel>, action: SelectRecurringPaymentTransactionReportAction) {
      ctx.patchState({ selectedRecurringPaymentTransactionReport: action.data});
    }
  
    @Action(GetRecurringPaymentTransactionReportAction)
    public getDebtorSummaryTransactionsAction(ctx: StateContext<RecurringPaymentTransactionReportStateModel>, action: GetRecurringPaymentTransactionReportAction) {
      return this._recurringService.getRecurringPaymentTransactionReport(action.debtorSummaryTransactionParameters).subscribe(data => {
        ctx.patchState({ selectedRecurringPaymentTransactionReport: data});
      })  
    }
  
  }