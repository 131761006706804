import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { CreateMerchantComponent } from './merchants/create-merchant/create-merchant.component';
import { MerchantListComponent } from './merchants/merchant-list/merchant-list.component';
import { AdminRoutingModule } from './admin-routing.module';

@NgModule({
  declarations: [
    CreateMerchantComponent,
    MerchantListComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule
  ]
})
export class AdminModule { }
