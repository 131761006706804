import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public token: string = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjY4MTBqUnNCZTRHc1Rka1NxcjY5aiJ9.eyJtZXJjaGFudCI6MjgwLCJ1c2VyTGV2ZWwiOjIsImlzcyI6Imh0dHBzOi8vZGV2LWFwaS51ay5hdXRoMC5jb20vIiwic3ViIjoiYXV0aDB8NjQ4N2EyMjNjM2QwMTU4MTBiMGRlMmFhIiwiYXVkIjpbImh0dHBzOi8vYXV0aDAtdWstZGV2LWFwaS1pZGVudGlmaWVyLmNvbSIsImh0dHBzOi8vZGV2LWFwaS51ay5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNzA0Nzk2MzczLCJleHAiOjE3MDQ4ODI3NzMsImF6cCI6IjBNZmxpd0M4SUVkWTFUeXR3NVFyTE5DbXU2dG1yOGFmIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImd0eSI6InBhc3N3b3JkIn0.MNdCvzRvKnCYXTu_Pcrb1PCfFe778QqFP-5_O7dvmOb1g3M1BwgK2PwWP1M4e0fOBQs4IT9ZMxoLxlTFBuhZkb0tx2Yv3Rs3JC6IhBO5AQm7nsNxntO06FZwuBySmN8cS5g5bhvpxGgnXYqfyVhWllmo78KXm9mHoOOTKzWmaRObFkgJ_EVXlAyu6wPkX1aQoC17-y1HUl-Zl9Jst6hIIJELAOTpDPTijPiBWtAtpvPWC4_J68xkA1qkDYlXRJ6zkKsBCxSx2j_dHSlrv3sSZq4IIzohzfkAu4-L1e24rX0sWGK0wdL6X6xjwAQ7z5Bd0g_nNDugPqMI9M-lDuEJzw';

  constructor(private http: HttpClient) { }

  getToken() {
    return this.token;
  }

  isAuthenticated() {
    return 'true';
  }
}
