<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Previous Billings</h5>
                </div>
                <div class="card-body">
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" sortable="billingId">Billing ID</th>
                                        <th scope="col" sortable="beginDate" >Start Date</th>
                                        <th scope="col" sortable="endDate" >End Date</th>
                                        <th scope="col" sortable="subTotal" >SubTotal</th>
                                        <th scope="col" sortable="vat" >VAT</th>
                                        <th scope="col" sortable="total" >Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of subscriptionReport$ | async ">
                                        <td scope="row">
                                            <a href="javascrip:void(0)" (click)="viewInvoice(item.billingId)"><span>{{item.billingId}}</span></a>
                                        </td>
                                        <td>
                                            {{item.beginDate}}
                                        </td>
                                        <td>{{item.endDate}}</td>
                                        <td>R{{item.subTotal | number:'1.2-2'}}</td>
                                        <td>R{{item.vat | number:'1.2-2'}}</td>
                                        <td>R{{item.total | number:'1.2-2'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center p-2">
                                <ngb-pagination
                                  [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="10">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->