export class InitiateRecurringTransactionRequestModel {
    public debtorName: string;
    public debtorSurname: string;
    public debtorContactNumber: string;
    public debtorEmailAddress: string;
    public debtorIdentifier: string;
    public cardNumber: string;
    public cardExpiryDate: string;
    public cardCVV: string;
    public collectionFrequency: string;
    public instalmentDay: number;
    public instalmentAmount: number;
    public instalmentCount: number;
    public collectionAutoRerun: boolean;
    public allowDynamicPayday: boolean;
    public earlyDecemberCollection: boolean;
}