<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Add User</h5>
                </div>
                <div class="card-body tab2-card">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>User Details</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="accountForm" class="needs-validation user-add" novalida>
                                    <!-- <h4>Account Details</h4> -->
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom0"><span>*</span>
                                                User Type</label></div>
                                        <div class="col-xl-8 col-md-7">
                                            <select class="custom-select form-select" required="">
                                                <option value="">--Select User Type--</option>
                                                <option value="1">Percent</option>
                                                <option value="2">Fixed</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom0"><span>*</span>
                                                Title</label></div>
                                        <div class="col-xl-8 col-md-7">
                                            <select class="custom-select form-select" required="">
                                                <option value="">--Select Title--</option>
                                                <option value="1">Percent</option>
                                                <option value="2">Fixed</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom0"><span>*</span>
                                                First Name</label></div>
                                        <div class="col-xl-8 col-md-7"><input class="form-control"
                                                id="validationCustom0" type="text" required=""></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom1"><span>*</span>
                                                Last Name</label></div>
                                        <div class="col-xl-8 col-md-7"><input class="form-control"
                                                id="validationCustom1" type="text" required=""></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom1"><span>*</span>
                                                Identity Number</label></div>
                                        <div class="col-xl-8 col-md-7"><input class="form-control"
                                                id="validationCustom1" type="text" required=""></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom1"><span>*</span>
                                                Date of Birth</label></div>
                                        <div class="col-xl-8 col-md-7">
                                            <div class="input-group input-grp-p">
                                                <input class="datepicker-here form-control digits" ngbDatepicker
                                                    formControlName="start_date" #f="ngbDatepicker">
                                                <div class="input-group-append">
                                                    <button class="btn calendar" (click)="f.toggle()" type="button"><i
                                                            class="fa fa-calendar"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom1"><span>*</span>
                                                Gender</label></div>
                                        <div class="col-xl-8 col-md-7">
                                            <select class="custom-select form-select" required="">
                                                <option value="">--Select Gender--</option>
                                                <option value="1">Percent</option>
                                                <option value="2">Fixed</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom2"><span>*</span>
                                                Contact Number 1</label></div>
                                        <div class="col-xl-8 col-md-7"><input class="form-control"
                                                id="validationCustom2" type="text" required=""></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom2"><span>*</span>
                                                Contact Number 2</label></div>
                                        <div class="col-xl-8 col-md-7"><input class="form-control"
                                                id="validationCustom2" type="text" required=""></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom2"><span>*</span>
                                                Email</label></div>
                                        <div class="col-xl-8 col-md-7"><input class="form-control"
                                                id="validationCustom2" type="text" required=""></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom2"><span>*</span>
                                                Active</label></div>
                                        <div class="col-xl-8 col-md-7">
                                            <input class="form-control" id="validationCustom2" type="text" required="">
                                        </div>
                                    </div>
                                    <!-- <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom3"><span>*</span>
                                                Password</label>
                                        </div>
                                        <div class="col-xl-8 col-md-7"><input class="form-control"
                                                id="validationCustom3" type="password" required=""></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom4"><span>*</span>
                                                Confirm Password</label></div>
                                        <div class="col-xl-8 col-md-7"><input class="form-control"
                                                id="validationCustom4" type="password" required=""></div>
                                    </div> -->
                                </form>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>Bank Account Details</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="accountForm" class="needs-validation user-add" novalida>
                                    <!-- <h4>Account Details</h4> -->
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom0"><span>*</span>
                                                Bank Name</label></div>
                                        <div class="col-xl-8 col-md-7">
                                            <input class="form-control"
                                                id="validationCustom0" type="text" required="">
                                            </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom1"><span>*</span>
                                                Account Number</label></div>
                                        <div class="col-xl-8 col-md-7"><input class="form-control"
                                                id="validationCustom1" type="text" required=""></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom1"><span>*</span>
                                                Account Type</label></div>
                                        <div class="col-xl-8 col-md-7">
                                            <select class="custom-select form-select" required="">
                                                <option value="">--Select Account Type--</option>
                                                <option value="1">Percent</option>
                                                <option value="2">Fixed</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom2"><span>*</span>
                                                Branch Code</label></div>
                                        <div class="col-xl-8 col-md-7"><input class="form-control"
                                                id="validationCustom2" type="text" required=""></div>
                                    </div>
                                </form>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink>Terminal Details</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="accountForm" class="needs-validation user-add" novalida>
                                    <!-- <h4>Account Details</h4> -->
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom0"><span>*</span>
                                                Serial Number</label></div>
                                        <div class="col-xl-8 col-md-7"><input class="form-control"
                                                id="validationCustom0" type="text" required=""></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom1"><span>*</span>
                                                Branch Name</label></div>
                                        <div class="col-xl-8 col-md-7"><input class="form-control"
                                                id="validationCustom1" type="text" required=""></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="validationCustom2"><span>*</span>
                                                IMEI Number</label></div>
                                        <div class="col-xl-8 col-md-7"><input class="form-control"
                                                id="validationCustom2" type="text" required=""></div>
                                    </div>
                                </form>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="4">
                            <a ngbNavLink>Permission</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="permissionForm" class="needs-validation user-add" novalidate="">
                                    <div class="permission-block">
                                        <div class="attribute-blocks">
                                            <h5 class="f-w-600 mb-3">Transaction Permission</h5>
                                            <div class="row mb-3">
                                                <div class="col-xl-3 col-sm-4">
                                                    <label>Authorisation only</label>
                                                </div>
                                                <div class="col-xl-9 col-sm-8">
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="edo-ani1">
                                                            <input class="radio_animated" id="edo-ani1" type="radio"
                                                                name="rdo-ani">
                                                            Allow
                                                        </label>
                                                        <label class="d-block" for="edo-ani2">
                                                            <input class="radio_animated" id="edo-ani2" type="radio"
                                                                name="rdo-ani" checked="">
                                                            Deny
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-xl-3 col-sm-4">
                                                    <label>Authorisation & Settlement</label>
                                                </div>
                                                <div class="col-xl-9 col-sm-8">
                                                    <div
                                                        class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                        <label class="d-block" for="edo-ani3">
                                                            <input class="radio_animated" id="edo-ani3" type="radio"
                                                                name="rdo-ani1">
                                                            Allow
                                                        </label>
                                                        <label class="d-block" for="edo-ani4">
                                                            <input class="radio_animated" id="edo-ani4" type="radio"
                                                                name="rdo-ani1" checked="">
                                                            Deny
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    <div class="pull-right">
                        <button type="button" class="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->