import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { DebtorSummaryTransactionsModel } from "../../models/recurring/debtor-summary-transactions.model";
import { RecurringService } from "../../services/recurring.service";
import { GetDebtorRecurringSummaryAction, SelectDebtorRecurringSummaryAction } from "../actions/recurring.action";

export class DebtorSummaryTransactionStateModel {
    public selectedDebtorSummaryTransactions: DebtorSummaryTransactionsModel;
  }
  
  @State<DebtorSummaryTransactionStateModel>({
    name: 'debtorSummaryTransactions',
    defaults: {
        selectedDebtorSummaryTransactions: null
    }
  })
  
  @Injectable()
  export class DebtorSummaryTransactionState {
  
      constructor(private _recurringService: RecurringService,
      private _router: Router){
    }
  
    @Selector()
    public static selectedDebtorSummaryTransactions(state: DebtorSummaryTransactionStateModel) {
      return state.selectedDebtorSummaryTransactions;
    }
  
    @Action(SelectDebtorRecurringSummaryAction)
    public selectedDebtorSummaryTransactionsAction(ctx: StateContext<DebtorSummaryTransactionStateModel>, action: SelectDebtorRecurringSummaryAction) {
      ctx.patchState({ selectedDebtorSummaryTransactions: action.data});
    }
  
    @Action(GetDebtorRecurringSummaryAction)
    public getDebtorSummaryTransactionsAction(ctx: StateContext<DebtorSummaryTransactionStateModel>, action: GetDebtorRecurringSummaryAction) {
      return this._recurringService.getDebtorSummaryTransactions(action.debtorSummaryTransactionParameters).subscribe(data => {
        ctx.patchState({ selectedDebtorSummaryTransactions: data});
      })  
    }
  
  }