import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { DebtorSummaryTransactionParameters } from 'src/app/core/models/common/request-parameters.model';
import { RecurringPaymentTransactionReportModel } from 'src/app/core/models/recurring/recurring-payment-transaction-report.model';
import { GetRecurringPaymentTransactionReportAction, SelectRecurringPaymentTransactionReportAction } from 'src/app/core/store/actions/recurring.action';
import { RecurringPaymentTransactionReportState, RecurringPaymentTransactionReportStateModel } from 'src/app/core/store/state/recurring-payment-transaction-report.state';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-recurring-transaction-report',
  templateUrl: './recurring-transaction-report.component.html',
  styleUrls: ['./recurring-transaction-report.component.scss']
})
export class RecurringTransactionReportComponent implements OnInit, OnDestroy {

  public searchCriteriaForm: UntypedFormGroup;
  public date: { year: number, month: number };
  public active = 1;

  public transactions$: Observable<RecurringPaymentTransactionReportModel[]>;
  public recurringPaymentTransactionReport$: Observable<RecurringPaymentTransactionReportModel[]>;
  public recurringPaymentTransactions: RecurringPaymentTransactionReportModel[] = [];
  public subscription: Subscription;
  public searchText;
  total$: Observable<number>;

  @Select(RecurringPaymentTransactionReportState.selectedRecurringPaymentTransactionReport) selectedTransactionReport$: Observable<RecurringPaymentTransactionReportModel[]>;

  constructor(private formBuilder: UntypedFormBuilder, public service: TableService, private store: Store) {
    this.retrieveTransactionReport();
    
    this.subscription = new Subscription();
    this.subscription = this.selectedTransactionReport$.subscribe(data => {
      this.store.dispatch(new SelectRecurringPaymentTransactionReportAction(data));
      this.transactions$ = service.tableItem$;
      this.total$ = service.total$;
      this.service.setUserData(data);
    });

    this.createSearchCriteriaFormorm();
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  createSearchCriteriaFormorm() {
    this.searchCriteriaForm = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
    })
  }

  retrieveTransactionReport() {
    let debtorSummaryTransactionParameters: DebtorSummaryTransactionParameters = {
      userId: 1050,
      startDate: '2024-03-25',
      endDate: '2024-04-10',
    }

    this.store.dispatch(new GetRecurringPaymentTransactionReportAction(debtorSummaryTransactionParameters));
  }

  ngOnInit() {
   
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
