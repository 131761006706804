import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Account Management', icon: 'settings', type: 'sub', children: [
				{ path: '/settings/account', title: 'Merchant Administration', type: 'link' },
			]
		},
		{
			title: 'Billing Management', icon: 'archive', type: 'sub', children: [
				{ path: '/billing/billing-subscription', title: 'Billing Subscriptions', type: 'link' }
				// { path: '/billing/billing-invoice', title: 'Billing Invoices', type: 'link' },
			]
		},
		{
			title: 'User Management', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/users/create-user', title: 'Create User', type: 'link' },
				{ path: '/users/list-user', title: 'User Administration', type: 'link' },
				{ path: '/users/user-deactivations', title: 'User Deactivations', type: 'link' }
			]
		},
		{
			title: 'Transaction Processing', icon: 'chrome', type: 'sub', active: false, children: [
				{ path: '/transaction-processing/process-transactions', title: 'Card Transactions', type: 'link' },
				{ path: '/transaction-processing/recurring-transactions', title: 'Recurring Transactions', type: 'link' },
			]
		},
		{
			title: 'Settlement Statements', icon: 'align-left', type: 'sub', active: false, children: [
				{ path: '/sales/previous-settlements', title: 'Previous Settlements', type: 'link' },
			]
		},
		{
			title: 'Reports', icon: 'file-text', type: 'sub', active: false, children: [
				{ path: '/sales/transaction-report', title: 'Transaction Report', type: 'link' },
				{ path: '/sales/recurring-transaction-report', title: 'Recurring Transaction Report', type: 'link' },
			]
		},
		// {
		// 	title: 'Summary Reports', path: '/reports', icon: 'bar-chart', type: 'link', active: false
		// },
		// {
		// 	title: 'Admin', icon: 'users', type: 'sub', active: false, children: [
		// 		{ path: '/merchants/create-merchant', title: 'Create Merchant', type: 'link' },
		// 		// { path: '/merchants/merchant-list', title: 'Merchant List', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Invoices & Billings', path: '/invoice', icon: 'archive', type: 'link', active: false
		// },
		// {
		// 	title: 'Log Out',path: '/auth/login', icon: 'log-in', type: 'link', active: false
		// },
		// {
		// 	title: 'Outstanding Payments', icon: 'box', type: 'sub', active: false, children: [
		// 		{
		// 			title: 'Netball', type: 'sub', children: [
						
		// 			]
		// 		},
		// 		{
		// 			title: 'Rugby Tour', type: 'sub', children: [
						
		// 			]
		// 		},
		// 	]
		// },
		// {
		// 	title: 'Pay with QR Code', icon: 'clipboard', type: 'sub', active: false, children: [
				
		// 	]
		// },
		// {
		// 	title: 'Collections', path: '/media', icon: 'camera', type: 'link', active: false
		// },
		// {
		// 	title: 'Vendors', icon: 'users', type: 'sub', active: false, children: [
		// 		{ path: '/vendors/list-vendors', title: 'Vendor List', type: 'link' },
		// 		{ path: '/vendors/create-vendors', title: 'Create Vendor', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'My Tickets', icon: 'chrome', type: 'sub', children: [
		// 		{ path: '/localization/translations', title: 'Translations', type: 'link' },
		// 		{ path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
		// 		{ path: '/localization/taxes', title: 'Taxes', type: 'link' },
		// 	]
		// },
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
