import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormGroup, UntypedFormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { CardTransactionDetailModel } from 'src/app/core/models/transaction/card-transaction-detail.model';
import { ProcessCardTransactionRequestModel } from 'src/app/core/models/transaction/process-card-transaction-request.model';
import { ProcessCardTransactionResponseModel } from 'src/app/core/models/transaction/process-card-transaction-response.model';
import { TransactionService } from 'src/app/core/services';
import { GetCardTransactionDetailAction } from 'src/app/core/store/actions/transaction.action';
import { CardTransactionDetailState } from 'src/app/core/store/state/card-transaction-detail.state';
import { APSModalComponent } from 'src/app/shared/components/modal/aps-modal/aps-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-payment-form',
  templateUrl: './customer-payment-form.component.html',
  styleUrls: ['./customer-payment-form.component.scss']
})
export class CustomerPaymentFormComponent implements OnInit, OnDestroy {
  public cardTransactionForm: FormGroup;
  public restrictionForm: UntypedFormGroup;
  public cardTransactionDetail: CardTransactionDetailModel;
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;
  public active = 1;

  public isLoading: boolean = false;
  public isSubmitted: boolean = false;
  public subscription: Subscription;

  public requestIdentifierParam: string;

  @ViewChild("apsModal") apsModal: APSModalComponent;

  @Select(CardTransactionDetailState.selectedCardTransactionDetail) selectedCardTransactionDetail$: Observable<CardTransactionDetailModel>;
  constructor(private formBuilder: UntypedFormBuilder, private store: Store, private route: ActivatedRoute,
    private spinner: NgxSpinnerService, public transactionService: TransactionService,) {
    this.subscription = new Subscription();

    this.route.queryParamMap.subscribe(params => {
       this.requestIdentifierParam = params.get('transactionRequestIdentifier');
      if (this.requestIdentifierParam != null) {
        this.retrieveCardTransactionDetail(this.requestIdentifierParam);
      }
    });

    
    this.subscription = this.selectedCardTransactionDetail$.subscribe(data => {
      this.cardTransactionDetail = data;
      console.log(this.cardTransactionDetail);
    });

    this.processCardTransactionForm();
    this.createRestrictionForm();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  processCardTransactionForm() : FormGroup {
    return this.cardTransactionForm = this.formBuilder.group({
      cardNumber: new FormControl(""),
      expiryMonth: new FormControl(""),
      expiryYear: new FormControl(""),
      cvv: new FormControl(""),
    })
  }

  get cardTx() {
    return this.cardTransactionForm.controls;
  }

  createRestrictionForm() {
    this.restrictionForm = this.formBuilder.group({
      products: new FormControl(""),
      category: new FormControl(""),
      min: new FormControl(""),
      max: ['']
    })
  }

  processCardTransaction() {
    this.isSubmitted = true;
    this.isLoading = true;

    this.spinner.show();

    const processCardTransactionRequestModel = new ProcessCardTransactionRequestModel();
    const cardTx = this.cardTx;

    processCardTransactionRequestModel.transactionReference = this.cardTransactionDetail.transactionReference;
    processCardTransactionRequestModel.transactionAmount = this.cardTransactionDetail.transactionAmount;
    processCardTransactionRequestModel.currency = "ZAR";
    processCardTransactionRequestModel.cardHolderName = this.cardTransactionDetail.cardHolderName;
    processCardTransactionRequestModel.cardNumber = "" + cardTx.cardNumber.value;
    processCardTransactionRequestModel.cardExpiry = "" + cardTx.expiryYear.value + cardTx.expiryMonth.value.toString().padStart(2, '0');
    processCardTransactionRequestModel.tokenise = false;
    processCardTransactionRequestModel.redirectUrl = "https://webhook.site/995ea0db-2e51-421c-a47f-c6620729d26c";
    processCardTransactionRequestModel.cardCVV = cardTx.cvv.value.toString().padStart(3, '0');
    processCardTransactionRequestModel.transactionRequestIdentifier = this.requestIdentifierParam;

    this.transactionService.processCardTransaction(processCardTransactionRequestModel).subscribe({
      next: (data: ProcessCardTransactionResponseModel) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
        this.authenticationModal(data.redirectPage);
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
      }
    })
  }

  retrieveCardTransactionDetail(transactionRequestIdentifier: string) {
    this.store.dispatch(new GetCardTransactionDetailAction(transactionRequestIdentifier));
  }

  showAuthenticationModal() {
    const htmlString = "<div id=\"threedsChallengeRedirect\" xmlns=\"http://www.w3.org/1999/html\" style=\" height: 100vh\"> <form id=\"threedsChallengeRedirectForm\" method=\"POST\" action=\"https://ky1i0ju09a.execute-api.eu-west-1.amazonaws.com/staging/3dsverify\" target=\"challengeFrame\"><input type=\"hidden\" name=\"creq\" value=\"52e29ad5-c00f-4a66-a839-31a4a425d63a\"/> </form> <iframe id=\"challengeFrame\" name=\"challengeFrame\" width=\"100%\" height=\"100%\"></iframe> <script id=\"authenticate-payer-script\"> var e=document.getElementById(\"threedsChallengeRedirectForm\"); if (e) { e.submit(); if (e.parentNode !== null) { e.parentNode.removeChild(e); } } </script> </div>";
    this.authenticationModal(htmlString);
    //this.apsModal.openModal(environment.apsApiUrl, 'ab06c300-f94e-42bb-a255-7cc5c3000334');
  }

  authenticationModal(htmlString: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const formElement = doc.querySelector('form#threedsChallengeRedirectForm') as HTMLFormElement;
    const actionAttributeValue = formElement ? formElement.action : null;
    console.log('Action Attribute Value:', actionAttributeValue);

    const creqInput = doc.querySelector('input[name="creq"]') as HTMLInputElement;
    const creqValue = creqInput ? creqInput.value : null;
    console.log('creqValue:', creqValue);

    this.apsModal.openModal(actionAttributeValue, creqValue);
  }
}

