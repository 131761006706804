import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProcessCardTransactionComponent } from './process-card-transaction/process-card-transaction.component';
import { RecurringTransactionsComponent } from './recurring-transactions/recurring-transactions.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'process-transactions',
                component: ProcessCardTransactionComponent,
                data: {
                    title: "Card Transaction",
                    breadcrumb: "Card Transaction"
                }
            },
            {
                path: 'recurring-transactions',
                component: RecurringTransactionsComponent,
                data: {
                    title: "Recurring Engine",
                    breadcrumb: "Recurring Engine"
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TransactionProcessingRoutingModule { }
